import { forwardRef, TextareaHTMLAttributes } from 'react'

import { styled } from 'styled-components'

import variables from 'core/styles/variables'

import { useFormDisabled } from 'loan/components/actionCards/shared/DisabledContext'

export type TextareaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'height' | 'value'> & {
  value?: string
  height?: string
  padding?: string
  noBorder?: boolean
  focusTextarea?: boolean
  setFocusTextarea?: (focus: boolean) => void
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      disabled,
      focusTextarea,
      height = 'auto',
      noBorder = false,
      padding = '8px',
      setFocusTextarea,
      value,
      ...inputProps
    },
    ref,
  ) => {
    const isDisabled = useFormDisabled(disabled)

    return (
      <StyledTextarea
        ref={(instance) => {
          if (typeof ref === 'function') {
            ref(instance)
          } else if (ref) {
            ref.current = instance
          }
          if (focusTextarea) {
            instance?.focus()
            if (instance?.value) {
              instance.setSelectionRange(instance.value.length, instance.value.length)
            }
            setFocusTextarea?.(false)
          }
        }}
        disabled={isDisabled}
        $height={height}
        $noBorder={noBorder}
        $padding={padding}
        value={value}
        {...inputProps}
      />
    )
  },
)

Textarea.displayName = 'Textarea'

export default Textarea

export const StyledTextarea = styled.textarea<{ $height: string; $padding: string; $noBorder: boolean }>`
  box-sizing: border-box;
  border-radius: 4px;
  padding: ${(p) => p.$padding};
  width: 100%;
  height: ${(p) => p.$height};
  resize: none;
  font-size: 16px;

  ::placeholder {
    color: ${variables.colorBlack50};
  }

  ${(p) =>
    p.$noBorder ?
      `
    border: none;
    `
    : `
    border: 1px solid ${variables.colorBlack20};

    &:hover {
      border-color: ${variables.colorBlack30};
    }

    &:focus {
      border: 1px solid ${variables.colorBlueSecondary};
      outline: none;
    }

    &:disabled {
      color: #999999;
      border-color: #e6e6e6;
      background-color: #f2f2f2;
    }
  `}
`
