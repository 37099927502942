import { FC, ReactNode, Suspense } from 'react'

import { styled } from 'styled-components'

import { Spinner } from 'core/components'

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

const defaultFallback = (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)

type RoutesProps = {
  fallback?: ReactNode
  [index: string]: any
}

const withSuspense =
  (Component): FC<RoutesProps> =>
  // eslint-disable-next-line react/display-name
  ({ fallback = defaultFallback, ...props }) => (
    <Suspense fallback={fallback}>
      <Component {...props} />
    </Suspense>
  )

export default withSuspense
