import { FC } from 'react'

import { styled } from 'styled-components'

import Skeleton from 'core/components/lib/Skeleton'
import { variables } from 'core/styles'

const DescriptionList = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px 16px;
  align-items: flex-start;
  margin: 0;

  & + & {
    margin-top: 16px;
  }
`

export default DescriptionList

export const DescriptionTerm = styled.dt`
  margin: 0;
  padding-top: 2px;
  text-transform: uppercase;
  color: ${variables.colorBlack50};
  font-size: 14px;
  font-weight: 500;
`

export const DescriptionDetails = styled.dd`
  margin: 0;
  color: ${variables.colorBlack90};
`

export const DescriptionListRowSkeleton: FC = () => {
  return (
    <>
      <SkeletonWrapper>
        <Skeleton maxWidth='92px'>92 x 18</Skeleton>
      </SkeletonWrapper>
      <SkeletonWrapper>
        <Skeleton maxWidth='280px'>280 x 18</Skeleton>
      </SkeletonWrapper>
    </>
  )
}

const SkeletonWrapper = styled(DescriptionTerm)`
  height: 22px;
  overflow: hidden;
`
