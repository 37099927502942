import { useState, useEffect } from 'react'

import QueryString from 'qs'
import { styled } from 'styled-components'

import Icon from 'core/components/Icon'
import Button from 'core/components/lib/Button'
import variables from 'core/styles/variables'

import { FallbackProps } from './defaultErrorBoundaries'

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`

const Title = styled.div`
  margin: 12px;
  padding: 6px 12px;
  color: ${variables.colorBlack80};
  font-size: 20px;
  font-weight: bold;
`

const MessageText = styled.span`
  display: inline-block;
`

const SupportLink = styled.a`
  color: ${variables.colorBluePrimary};

  &:hover {
    text-decoration: underline;
  }
`
const Bolder = styled.span`
  font-weight: 500;
`

export type ReportErrorFallbackProps = FallbackProps & {
  errorAt: string
  debugId: string
  peachRequestId?: string
}

const DefaultReportErrorFallback = ({ className, errorAt, debugId, peachRequestId }: ReportErrorFallbackProps) => {
  const { origin = '', pathname = '' } = window.location || {}
  const url = `${origin}${pathname}`

  const [copySuccess, setCopySuccess] = useState<null | boolean>(null)
  const [copySuccessTimeout, setCopySuccessTimeout] = useState<ReturnType<typeof setTimeout>>()

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`Error on page ${url} with Error ID ${debugId} at ${errorAt}`)
      setCopySuccess(true)
    } catch {
      setCopySuccess(false)
    }
    setCopySuccessTimeout(
      setTimeout(() => {
        setCopySuccess(null)
      }, 2000),
    )
  }

  useEffect(() => {
    // Ensure teardown of timeout
    return () => {
      if (copySuccessTimeout) {
        clearTimeout(copySuccessTimeout)
      }
    }
  }, [copySuccessTimeout])

  return (
    <Message className={className}>
      <Title>An unexpected error has occurred</Title>
      <MessageText>
        <p>
          Please{' '}
          <SupportLink
            href={`https://peachfinance.zendesk.com/hc/en-us/requests/new?${QueryString.stringify({
              tf_subject: `Error on page ${url}`,
              tf_description: `<p></p><p>---<br/>Datetime: ${errorAt || 'n/a'}<br/>URL: ${url || 'n/a'}<br/>Error ID: ${
                debugId || 'n/a'
              }<br/>Request ID: ${peachRequestId || 'n/a'}`,
            })}`}
            target='_blank'
          >
            contact Peach support
          </SupportLink>{' '}
          and describe what you were doing when this error occurred. Including a screenshot with your report can aid in
          identifying and resolving the issue.
        </p>
        <table>
          <tr>
            <td>
              <Bolder>Page:</Bolder>
            </td>
            <td>{url}</td>
          </tr>
          <tr>
            <td>
              <Bolder>Datetime:</Bolder>
            </td>
            <td>{errorAt}</td>
          </tr>
          <tr>
            <td>
              <Bolder>Error ID:</Bolder>
            </td>
            <td>{debugId}</td>
          </tr>
        </table>
      </MessageText>
      <Button onClick={copyToClipboard} color={variables.colorBluePrimary}>
        {copySuccess === null ?
          <>
            Copy &nbsp;
            <Icon name='content_copy' color={variables.colorBluePrimary} />
          </>
        : copySuccess ?
          <>
            Copied &nbsp;
            <Icon name='check' color={variables.colorBluePrimary} />
          </>
        : <>
            Error &nbsp;
            <Icon name='warning' color={variables.colorRed} />
          </>
        }
      </Button>
    </Message>
  )
}

export default DefaultReportErrorFallback
