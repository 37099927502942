/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const getNotificationDataInternal: OperationData<Types.GetNotificationDataInternal> = {
  method: 'get',
  name: 'getNotificationDataInternal',
  summary: `Get reminders that should be sent today.`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/{repaymentNotificationTypeId}/reminders',
  queryKey: ({ companyId, loanTypeId, repaymentNotificationTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    repaymentNotificationTypeId,
    'reminders',
  ],
}

export const getNotificationTypesByCompany: OperationData<Types.GetNotificationTypesByCompany> = {
  method: 'get',
  name: 'getNotificationTypesByCompany',
  summary: `Get all company notification types`,
  path: '/companies/{companyId}/repayment-engine/notifications',
  queryKey: ({ companyId }) => ['companies', companyId, 'repayment-engine', 'notifications'],
}

export const getProductsWithNotifications: OperationData<Types.GetProductsWithNotifications> = {
  method: 'get',
  name: 'getProductsWithNotifications',
  summary: `Get all products with notifications for a company`,
  path: '/companies/{companyId}/repayment-engine/products-with-notifications',
  queryKey: ({ companyId }) => ['companies', companyId, 'repayment-engine', 'products-with-notifications'],
}

export const getSubjectsWithNotifications: OperationData<Types.GetSubjectsWithNotifications> = {
  method: 'get',
  name: 'getSubjectsWithNotifications',
  summary: `Get distinct subjects with notifications for a company`,
  path: '/companies/{companyId}/repayment-engine/subjects-with-notifications',
  queryKey: ({ companyId }) => ['companies', companyId, 'repayment-engine', 'subjects-with-notifications'],
}

export const getTriggersWithNotifications: OperationData<Types.GetTriggersWithNotifications> = {
  method: 'get',
  name: 'getTriggersWithNotifications',
  summary: `Get distinct triggers with notifications for a company`,
  path: '/companies/{companyId}/repayment-engine/triggers-with-notifications',
  queryKey: ({ companyId }) => ['companies', companyId, 'repayment-engine', 'triggers-with-notifications'],
}

export const getNotificationTypesInternal: OperationData<Types.GetNotificationTypesInternal> = {
  method: 'get',
  name: 'getNotificationTypesInternal',
  summary: `Get product notification types`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/notifications',
  queryKey: ({ companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    'notifications',
  ],
}

export const createNotificationTypeInternal: OperationData<Types.CreateNotificationTypeInternal> = {
  method: 'post',
  name: 'createNotificationTypeInternal',
  summary: `Create a notification type`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/notifications',
  queryKey: ({ companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    'notifications',
  ],
}

export const getNotificationTypeById: OperationData<Types.GetNotificationTypeById> = {
  method: 'get',
  name: 'getNotificationTypeById',
  summary: `Get notification type by ID`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/{repaymentNotificationTypeId}',
  queryKey: ({ companyId, loanTypeId, repaymentNotificationTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    repaymentNotificationTypeId,
  ],
}

export const updateNotificationTypeInternal: OperationData<Types.UpdateNotificationTypeInternal> = {
  method: 'put',
  name: 'updateNotificationTypeInternal',
  summary: `Update a notification type`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/{repaymentNotificationTypeId}',
  queryKey: ({ companyId, loanTypeId, repaymentNotificationTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    repaymentNotificationTypeId,
  ],
}

export const deleteNotificationTypeInternal: OperationData<Types.DeleteNotificationTypeInternal> = {
  method: 'delete',
  name: 'deleteNotificationTypeInternal',
  summary: `Delete a notification type`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/{repaymentNotificationTypeId}',
  queryKey: ({ companyId, loanTypeId, repaymentNotificationTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    repaymentNotificationTypeId,
  ],
}
