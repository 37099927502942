import { useMemo } from 'react'

import _ from 'lodash'

import useGetZendeskToken from 'core/data/authQueries/useGetZendeskToken'
import { useCompanyId } from 'core/SystemProvider/CompanyIdProvider'
import { useCompanyLinks, useCompanyUI } from 'core/SystemProvider/CustomizationsProvider'
import { useUserType } from 'core/SystemProvider/UserTypeProvider'

interface Props {
  isMobile?: boolean
  helpUrl?: string
  userType?: string
  ui?: { [key: string]: any }
  zendeskToken?: string
  zendeskTokenQuery?: {
    refetch?: () => void
    isError?: boolean
  }
}

export interface Option {
  label: string
  action?: string
  href?: string
  icon: string
  disabled?: boolean
  hide?: boolean
  agent?: boolean
}

const getAccountOptions = (options: Props = {}): Array<Option> | [] => {
  const { isMobile, helpUrl, userType, ui, zendeskToken, zendeskTokenQuery } = options

  const showHelp = ui?.help !== 'hidden'
  const showSignOut = ui?.signOut !== 'hidden'
  const showAccountSettings = ui?.accountSettings !== 'hidden'
  const showContactUs = ui?.contactUs !== 'hidden'

  const items = [
    {
      label: 'Loans',
      action: '/loans',
      icon: 'home',
      hide: !isMobile,
    },
    {
      label: 'Settings',
      action: '/account-settings',
      icon: 'settings',
      disabled: false,
      hide: !showAccountSettings,
    },
    {
      label: 'Help & FAQ',
      href: helpUrl,
      icon: 'help',
      disabled: !helpUrl,
      hide: !showHelp,
    },
    {
      label: 'Contact us',
      action: '/contact-us',
      icon: 'alternate_email',
      disabled: false,
      hide: !showContactUs,
    },
    {
      label: 'Sign out',
      action: '/sign-out',
      icon: 'exit_to_app',
      disabled: userType === 'agent',
      hide: !showSignOut,
    },
    {
      label: 'Manage borrower information',
      action: '/borrower-information',
      icon: 'account_circle',
      agent: true,
    },
    {
      label: 'Manage identifying information',
      action: '/identifying-information',
      icon: 'portrait',
      agent: true,
    },
    {
      label: 'Manage consents',
      action: '/manage-consents',
      icon: 'dns',
      agent: true,
    },
    {
      label: 'Peach support',
      href: `https://peachfinance.zendesk.com/access/jwt?jwt=${zendeskToken}`,
      icon: 'help',
      agent: true,
      isExternal: true,
      externalAction: zendeskTokenQuery?.refetch,
      hide: zendeskTokenQuery?.isError || _.isEmpty(zendeskToken),
    },
  ]

  return _.reject(items, (item) => {
    return item.hide || (userType !== 'agent' && item.agent === true)
  })
}

const useAccountOptions = ({ isMobile }: { isMobile?: boolean } = {}): Array<Option> | [] => {
  const ui = useCompanyUI()
  const { helpUrl } = useCompanyLinks()
  const userType = useUserType()
  const companyId = useCompanyId()
  const [zendeskToken, zendeskTokenQuery] = useGetZendeskToken({
    companyId,
  })

  return useMemo(() => {
    return getAccountOptions({
      isMobile,
      helpUrl,
      userType,
      ui,
      zendeskToken,
      zendeskTokenQuery,
    })
  }, [isMobile, helpUrl, userType, ui, zendeskToken, zendeskTokenQuery])
}

export { getAccountOptions }
export default useAccountOptions
