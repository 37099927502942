import { FC } from 'react'

import { isEmpty } from 'lodash'

import { withErrorBoundary } from 'core/components/lib/ErrorBoundary/ErrorBoundary'
import { useGetZendeskToken } from 'core/data'
import { useCompanyId } from 'core/hooks'

import { UserDropdownLinkExternal } from './styled'

const UserPeachSupportLink: FC = () => {
  const companyId = useCompanyId()
  const [zendeskToken, zendeskTokenQuery] = useGetZendeskToken({
    companyId,
    options: {
      throwOnError: true,
    },
  })

  if (isEmpty(zendeskToken)) return null

  return (
    <UserDropdownLinkExternal
      href={`https://peachfinance.zendesk.com/access/jwt?jwt=${zendeskToken}`}
      target='_blank'
      onClick={() => zendeskTokenQuery?.refetch()}
    >
      Peach support
    </UserDropdownLinkExternal>
  )
}

UserPeachSupportLink.displayName = 'PeachSupportLink'

export default withErrorBoundary(UserPeachSupportLink, {
  isAgent: true,
  ErrorFallback: () => null,
  ReportErrorFallback: () => null,
})
