import { FC, PropsWithChildren } from 'react'

import { LDProvider } from 'launchdarkly-react-client-sdk'

import DraftContextProvider from 'contexts/DraftContext'
import FileUploadContextProvider from 'contexts/FileUploadContext/provider'

import { useErrorLoggingScope } from 'core/system/useErrorLoggingScope'
import CommonProviders from 'core/SystemProvider/CommonProvider'
import { TimeProvider } from 'core/time'
import { FrontendPreauthBootstrap } from 'core/types'

import { CustomizationsProvider } from './CustomizationsProvider'
import useActivityRenewal from './session/useActivityRenewal'
import { UserTypeProvider } from './UserTypeProvider'

type SystemProvidersProps = PropsWithChildren<{
  appDomainType: 'agent' | 'borrower' | 'admin'
  userType: 'agent' | 'borrower'
  timezone: string
  companyId: string
  company: FrontendPreauthBootstrap['company']
}>

const SystemProviders: FC<SystemProvidersProps> = ({
  appDomainType,
  userType,
  companyId,
  timezone,
  company,
  children,
}) => {
  useErrorLoggingScope({
    company,
    companyId,
    appDomainType,
    userType,
    timezone,
  })

  useActivityRenewal()

  return (
    <LDProvider
      clientSideID={import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID}
      context={{
        key: companyId,
      }}
    >
      <CommonProviders companyId={companyId}>
        <UserTypeProvider userType={userType}>
          <TimeProvider timezone={timezone}>
            <CustomizationsProvider company={company}>
              <DraftContextProvider>
                <FileUploadContextProvider>{children}</FileUploadContextProvider>
              </DraftContextProvider>
            </CustomizationsProvider>
          </TimeProvider>
        </UserTypeProvider>
      </CommonProviders>
    </LDProvider>
  )
}

export default SystemProviders
