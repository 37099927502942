import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'

import { PDFViewer } from 'pdfjs-dist/web/pdf_viewer.mjs'
import { styled } from 'styled-components'

import IconButton from 'core/components/lib/IconButton'
import { Select } from 'core/components/lib/Select'
import TextInput from 'core/components/lib/TextInput'
import { R, assert } from 'core/helpers'
import { variables } from 'core/styles'

import FindPopover from './FindPopover'
import PrintButton from './PrintButton'
import ThumbnailsButton from './ThumbnailsButton'

type ToolbarProps = {
  pdfViewer: PDFViewer
  filename: string
}

const Toolbar: FC<ToolbarProps> = ({ pdfViewer, filename }) => {
  const pageNumberRef = useRef() as MutableRefObject<HTMLInputElement>

  const [pageNumber, setPageNumber] = useState(0)
  const [scale, setScale] = useState<null | number | string>(null)

  const [pageNumberValue, setPageNumberValue] = useState('')

  useEffect(() => {
    pdfViewer.currentScaleValue = 'auto'

    pdfViewer.eventBus.on('updateviewarea', (data) => {
      setPageNumber(data.location.pageNumber)
      setPageNumberValue(data.location.pageNumber.toString())
    })
    pdfViewer.eventBus.on('scalechanging', (data) => setScale(data.presetValue || data.scale))
  }, [pdfViewer])

  const scrollPageIntoView = (page: number) =>
    pdfViewer.scrollPageIntoView({ pageNumber: Math.max(1, Math.min(page, pdfViewer.pagesCount)) })

  return (
    <Box>
      <ThumbnailsButton pdfViewer={pdfViewer} pageNumber={pageNumber} onClick={scrollPageIntoView} />
      <FindPopover eventBus={pdfViewer.eventBus} />

      <Spacer />

      <IconButton
        fontSize='18px'
        color='inherit'
        name='keyboard_arrow_up'
        disabled={pageNumber <= 1}
        onClick={() => {
          pdfViewer.previousPage()
        }}
      />
      <IconButton
        fontSize='18px'
        color='inherit'
        name='keyboard_arrow_down'
        disabled={pageNumber >= pdfViewer.pagesCount}
        onClick={() => {
          pdfViewer.nextPage()
        }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault()
          scrollPageIntoView(parseInt(pageNumberValue))
        }}
      >
        <StyledTextInput
          variant='underline'
          format='integer'
          ref={pageNumberRef}
          value={pageNumberValue}
          onFocus={() => pageNumberRef.current.select()}
          onChange={setPageNumberValue}
          onBlur={() => scrollPageIntoView(parseInt(pageNumberValue))}
          onKeyDown={(e) => {
            switch (e.key) {
              case 'ArrowDown':
                pdfViewer.nextPage()
                break
              case 'ArrowUp':
                pdfViewer.previousPage()
                break
              default:
                return
            }
          }}
        />
        <span> of {pdfViewer.pagesCount}</span>
      </form>

      <Spacer />

      <IconButton
        fontSize='18px'
        color='inherit'
        name='remove'
        disabled={R.isNumber(scale) && scale <= 0.25}
        onClick={() => pdfViewer.decreaseScale()}
      />
      <SelectWrapper>
        <Select
          classNamePrefix='inline'
          value={scale}
          options={R.compact([
            { value: 'auto', label: 'Automatic' },
            { value: 'page-actual', label: 'Actual Size' },
            { value: 'page-fit', label: 'Page Fit' },
            { value: 'page-width', label: 'Page Width' },
            { value: 0.5, label: '50%' },
            { value: 0.75, label: '75%' },
            { value: 1, label: '100%' },
            { value: 1.25, label: '125%' },
            { value: 1.5, label: '150%' },
            { value: 2, label: '200%' },
            R.isNumber(scale) && { value: scale, label: `${Math.round(scale * 100)}%` },
          ])}
          onChange={(value) => {
            if (R.isNumber(value)) {
              pdfViewer.currentScale = value
            } else if (value) {
              pdfViewer.currentScaleValue = value
            }
          }}
        />
      </SelectWrapper>
      <IconButton
        fontSize='18px'
        color='inherit'
        name='add'
        disabled={R.isNumber(scale) && scale >= 5}
        onClick={() => pdfViewer.increaseScale()}
      />

      <Spacer />

      <IconButton
        fontSize='18px'
        color='inherit'
        name='rotate_right'
        onClick={() => {
          pdfViewer.pagesRotation += 90
        }}
      />
      <IconButton
        fontSize='18px'
        color='inherit'
        name='rotate_left'
        onClick={() => {
          pdfViewer.pagesRotation -= 90
        }}
      />
      <IconButton
        fontSize='18px'
        color='inherit'
        name='download'
        onClick={async () => {
          assert(pdfViewer.pdfDocument, 'No PDF document loaded')
          pdfViewer.downloadManager?.downloadData(await pdfViewer.pdfDocument.getData(), filename)
        }}
      />
      <PrintButton pdfViewer={pdfViewer} />
    </Box>
  )
}

export default Toolbar

const Box = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  background-color: ${variables.colorBlack80};
  padding: 0 16px;
  color: white;
  font-size: 14px;
`

const Spacer = styled.div`
  flex-grow: 1;
`

const SelectWrapper = styled.div`
  width: 120px;

  input {
    color: white !important;
  }

  div:not(:has(> input)) {
    background-color: ${variables.colorBlack80};
    color: white;
  }

  div[role='option']:hover,
  div[role='option']:hover div {
    background-color: ${variables.colorBlack90};
  }
`

const StyledTextInput = styled(TextInput)`
  width: 32px;
  text-align: center;
`
