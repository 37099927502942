import { forwardRef, InputHTMLAttributes, ReactNode } from 'react'

import { styled, css } from 'styled-components'

import { variables } from 'core/styles'

export type InputVariant = 'outline' | 'underline' | 'none'

export type TextInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> & {
  className?: string
  /** @deprecated use onChange instead */
  onValueChange?: (val: string) => void
  variant?: InputVariant
  width?: string
  prefix?: ReactNode
  postfix?: ReactNode
}

const amountPattern = /^(\d+)?([.]?\d{0,2})?$/

/** @deprecated Use `core/components/TextInput` */
export const AmountTextInput = forwardRef<HTMLInputElement, Omit<TextInputProps, 'onValueChange'>>(
  ({ prefix = '$', placeholder = '0.00', onChange, onBlur, ...props }, ref) => (
    <TextInput
      onChange={(e) => {
        if (!amountPattern.test(e.target.value)) {
          e.preventDefault()
          return
        }

        onChange?.(e)
      }}
      onBlur={(e) => {
        if (e.target.value !== '') {
          e.target.value = Number(e.target.value).toFixed(2)
          onChange?.(e)
        }

        onBlur?.(e)
      }}
      placeholder={placeholder}
      prefix={prefix}
      ref={ref}
      {...props}
    />
  ),
)

AmountTextInput.displayName = 'AmountTextInput'

/** @deprecated Use `core/components/TextInput` */
const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ onChange, onValueChange, prefix, postfix, variant, width, disabled, className, ...props }, ref) =>
    prefix || postfix ?
      <Wrapper className={className} variant={variant} width={width} disabled={disabled}>
        <Fixture>{prefix}</Fixture>
        <input
          ref={ref}
          onChange={(e) => {
            onChange?.(e)
            onValueChange?.(e.target.value)
          }}
          disabled={disabled}
          {...props}
        />
        <Fixture>{postfix}</Fixture>
      </Wrapper>
    : <StyledInput
        className={className}
        variant={variant}
        width={width}
        ref={ref}
        onChange={(e) => {
          onChange?.(e)
          onValueChange?.(e.target.value)
        }}
        disabled={disabled}
        {...props}
      />,
)

TextInput.displayName = 'TextInput'

const Fixture = styled.span`
  color: ${variables.colorBlack60};
`

export const common = css<{ width?: string; variant?: InputVariant; disabled?: boolean }>`
  box-sizing: border-box;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
  width: ${(p) => (p.width ? p.width : 'auto')};

  ${(p) =>
    p.disabled &&
    `
    // Matching the ReactSelect default styles (?) here for consistency
    color: #999999;
    background-color: #f2f2f2;
    border-color: #e6e6e6;
  `}

  ${(p) =>
    (p.variant === 'underline' || p.variant === 'outline') &&
    `
      border-color: ${variables.colorBlack20};

      &:hover {
        border-color: ${variables.colorBlack30};
      }
  `}

  ${(p) =>
    p.variant === 'outline' &&
    `
      padding: 8px;
      border-width: 1px;
      border-radius: 4px;
    `}

  &:focus-within:not([readonly]) {
    outline: none;
    border-color: ${variables.colorBlueSecondary};
  }
`

export const commonInput = css`
  border: none;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: 14px;

  ::placeholder {
    color: ${variables.colorBlack50};
  }
`

export const Wrapper = styled.div<{ width?: string; variant?: InputVariant; disabled?: boolean }>`
  ${common}
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  column-gap: 2px;
  align-items: center;

  input {
    ${commonInput}
    &:focus-within {
      outline: none;
      border: none;
    }
  }
`

const StyledInput = styled.input<{ width?: string; variant?: InputVariant; disabled?: boolean }>`
  ${commonInput}
  ${common}
`

export default TextInput
