import PropTypes from 'prop-types'
import { styled } from 'styled-components'

import variables from 'core/styles/variables'

const DEFAULT_LABEL_WIDTH = '200px'

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const RowLeft = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  padding-top: ${(props) => (props.isFirst ? '0' : '16px')};
  padding-right: 16px;
  padding-bottom: 16px;
  width: ${(props) => props.leftWidth};
  text-align: right;
  color: ${variables.colorBlack60};
  font-weight: normal;
  font-style: normal;
`

// padding-top for both left and content are set to zero for the first item
// so that it's content will align with the list's overall left

const RowContent = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: ${(props) => {
    return props.isFirst ? 'none' : `1px solid ${variables.colorBlack20}`
  }};
  padding-top: ${(props) => (props.isFirst ? '0' : '16px')};
  padding-bottom: 16px;
  width: 100%;
`

const CardSectionListRow = ({ children, left, leftWidth, isFirst }) => {
  return (
    <RowContainer isFirst={isFirst}>
      <RowLeft isFirst={isFirst} leftWidth={leftWidth}>
        {left}
      </RowLeft>
      <RowContent isFirst={isFirst}>{children}</RowContent>
    </RowContainer>
  )
}

CardSectionListRow.propTypes = {
  children: PropTypes.node,
  left: PropTypes.node,
  leftWidth: PropTypes.string,
  isFirst: PropTypes.bool,
}

CardSectionListRow.defaultProps = {
  leftWidth: DEFAULT_LABEL_WIDTH,
}

export default CardSectionListRow
