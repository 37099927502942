import { FC } from 'react'

import { styled } from 'styled-components'

import { useCompanyLogos } from 'core/hooks'

/*

Branding logos should be used in-app in exactly the dimensions present 
in the config below.  this is to ensure that the uploaded assets 
will behave as expected.  i.e. do not override width and height, className, or constrain them into
fixed width or fixed height containers smaller than their explicit dimensions.  

(Even if they are aesthetically bad, they will be predictably bad 
and the solution will be fixing the uploaded asset)

We should reuse these asset types when it is sensible (ex: appLargeCentered was designed for login, but works for 
<DocumentUpload> because they are similar single-item screens)

But if a new use-case comes up, it's quite possible we need to add an additional key 
to the logos object in the company config and to extend the LOGO_TYPE_CONFIG here appropriately.  

*/

type LogoType = 'appLargeCentered' | 'appRegularNavigation' | 'appCompactNavigation'

const LOGO_TYPE_CONFIG = {
  // Login, Login OTC, DocumentUploadPage
  appLargeCentered: {
    width: 400,
    height: 64,
    backgroundPosition: 'center',
  },

  // Desktop layout, Navbar (when in CRM Main page)
  appRegularNavigation: {
    width: 304,
    height: 48,
    backgroundPosition: 'left',
  },
  // Mobile Layout
  appCompactNavigation: {
    width: 200,
    height: 32,
    backgroundPosition: 'left',
  },
  fallback: {
    width: 0,
    height: 0,
  },
}

type CompanyLogoProps = {
  url?: string
  type: LogoType
}

const CompanyLogo: FC<CompanyLogoProps> = ({ url, type }) => {
  const logos = useCompanyLogos() || {}
  const { width, height, backgroundPosition } = LOGO_TYPE_CONFIG[type] || LOGO_TYPE_CONFIG.fallback
  return (
    <Logo
      data-testid='logo'
      width={width}
      height={height}
      url={url || (logos as any)[type] || logos.primary || logos.small}
      backgroundPosition={backgroundPosition}
    />
  )
}

export default CompanyLogo

export const Logo = styled.span<{ backgroundPosition?: string; height?: number; width?: number; url?: string }>`
  display: inline-block;
  background-image: ${(p) => (p.url ? `url(${p.url})` : '#967bb6')};
  background-position: ${(p) => p.backgroundPosition || 'center'};
  background-repeat: no-repeat;
  background-size: contain;
  width: ${(p) => (p.width ? `${p.width}px` : '0')};
  height: ${(p) => (p.height ? `${p.height}px` : '0')};
  line-height: 0;
`
