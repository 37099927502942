import { FC, useEffect, useRef, useState } from 'react'

import { PDFPageProxy } from 'pdfjs-dist'
import { PDFViewer } from 'pdfjs-dist/web/pdf_viewer.mjs'
import { styled } from 'styled-components'

import IconButton from 'core/components/lib/IconButton'
import { R, assert } from 'core/helpers'
import { variables } from 'core/styles'

export const renderPages = async (
  pdfViewer: PDFViewer,
  intent: 'display' | 'print',
  scale: number | ((page: PDFPageProxy) => number),
) => {
  assert(pdfViewer.pdfDocument, 'No PDF document loaded')

  const pages: Array<string> = []
  const canvas = document.createElement('canvas')

  for (let i = 1; i < pdfViewer.pdfDocument.numPages + 1; i++) {
    const pdfPage = await pdfViewer.pdfDocument?.getPage(i)

    const viewport = pdfPage.getViewport({
      scale: R.isNumber(scale) ? scale : scale(pdfPage),
      rotation: pdfViewer.pagesRotation,
    })

    canvas.width = viewport.width
    canvas.height = viewport.height

    const canvasContext = canvas.getContext('2d', { alpha: false })
    assert(canvasContext, 'Failed to get canvas context')

    canvasContext.save()
    canvasContext.fillStyle = 'rgb(255, 255, 255)'
    canvasContext.fillRect(0, 0, canvas.width, canvas.height)
    canvasContext.restore()

    await pdfPage.render({ canvasContext, viewport, intent }).promise

    pages.push(canvas.toDataURL())
  }

  canvas.width = 0
  canvas.height = 0

  return pages
}

type ThumbnailProps = {
  src: string
  pageNumber: number
  selected: boolean
  onClick: () => void
}

const Thumbnail: FC<ThumbnailProps> = ({ src, pageNumber, selected, onClick }) => {
  const ref = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (selected) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }, [selected])

  return (
    <Button ref={ref} onClick={onClick} $selected={selected}>
      <img src={src} alt={'Page ' + pageNumber} />
      {pageNumber}
    </Button>
  )
}

type ThumbnailsButtonProps = {
  pdfViewer: PDFViewer
  pageNumber: number
  onClick: (pageNumber: number) => void
}

const ThumbnailsButton: FC<ThumbnailsButtonProps> = ({ pdfViewer, pageNumber, onClick }) => {
  const [pages, setPages] = useState<Array<string>>([])

  return (
    <>
      <IconButton
        fontSize='18px'
        color='inherit'
        name='thumbnail_bar'
        outlined
        checked={pages.length > 0}
        onClick={async () => {
          if (pages.length) {
            setPages([])
          } else {
            setPages(
              await renderPages(
                pdfViewer,
                'display',
                (pdfPage) => (98 * window.devicePixelRatio) / pdfPage.getViewport({ scale: 1 }).width,
              ),
            )
          }
        }}
      />

      {pages.length > 0 && (
        <Sidebar>
          {R.map.indexed(pages, (src, i) => (
            <Thumbnail
              key={i}
              src={src}
              pageNumber={i + 1}
              selected={i + 1 === pageNumber}
              onClick={() => onClick(i + 1)}
            />
          ))}
        </Sidebar>
      )}
    </>
  )
}

export default ThumbnailsButton

const Sidebar = styled.div`
  display: flex;
  position: absolute;
  inset: 39px auto 0 0;
  flex-direction: column;
  gap: 16px;
  z-index: 10;
  background-color: ${variables.colorBlack90};
  padding: 8px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Button = styled.button<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  color: white;

  img {
    border: 2px solid ${(p) => (p.$selected ? variables.colorBluePrimary : 'transparent')};
    border-radius: 4px;
  }
`
