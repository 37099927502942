import { FC } from 'react'

import { styled } from 'styled-components'

import { variables } from 'core/styles'

import Textarea, { TextareaProps } from './Textarea'

const TextareaAutoHeight: FC<Omit<TextareaProps, 'height'> & { maxRows?: number }> = ({
  className,
  value,
  rows = 3,
  maxRows,
  maxLength,
  ...props
}) => (
  <Box className={className} $rows={rows} $maxRows={maxRows}>
    <StyledTextarea value={value} maxLength={maxLength} {...props} />
    <GrowBox>{value}&#8203;</GrowBox>
    {maxLength && value && value.length > 0 && (
      <Counter>
        {value.length}/{maxLength}
      </Counter>
    )}
  </Box>
)

export default TextareaAutoHeight

export const Box = styled.div<{ $rows: number; $maxRows?: number }>`
  position: relative;
  width: 100%;
  min-height: ${(p) => p.$rows * 24 + 18}px;
  ${({ $rows, $maxRows }) => (!!$maxRows && $maxRows >= $rows ? `max-height: ${$maxRows * 24 + 18}px;` : '')}
  line-height: 1.5;
  font-size: 16px;
`

const StyledTextarea = styled(Textarea)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
`

const GrowBox = styled.div`
  visibility: hidden;
  border: 1px solid transparent;
  padding: 8px;
  white-space: pre-wrap;
  font-family: inherit;
`

const Counter = styled.div`
  position: absolute;
  right: 8px;
  bottom: -11px;
  z-index: 1;
  background-color: white;
  padding: 0 2px;
  color: ${variables.colorBlack60};
  font-size: 14px;
`
