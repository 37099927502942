import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react'

import { useLocation } from 'react-use'

export enum AuthTypes {
  Basic = 'basic',
  Google = 'google',
  OneTimeCodeEmail = 'oneTimeCodeEmail',
  OneTimeCodeText = 'oneTimeCodeText',
  SAML = 'SAML',
}

export const isAuthType = (str: string): str is AuthTypes => (Object.values(AuthTypes) as Array<string>).includes(str)

type LoginInfoProviderCommon = {
  appDomainType: 'agent' | 'borrower' | 'admin'
  userType: 'agent' | 'borrower'
  companyId: string
  authType: AuthTypes
  ssoUrl: string
  authValueType: 'email' | 'username' | 'phone' | 'samlSubjectNameId'
}

type LoginInfoProviderProps = PropsWithChildren<LoginInfoProviderCommon>

type LoginInfoProviderContext = LoginInfoProviderCommon & { forceAuthType: boolean }

const LoginInfoContext = createContext<LoginInfoProviderContext>({} as LoginInfoProviderContext)

export const useLoginInfo = () => useContext(LoginInfoContext)

export const LoginInfoProvider: FC<LoginInfoProviderProps> = ({
  appDomainType,
  userType,
  authType,
  authValueType,
  companyId,
  children,
  ssoUrl,
}) => {
  const { search } = useLocation()
  const authTypeOverride = new URLSearchParams(search).get('authType') ?? ''

  const value = useMemo(() => {
    const forceAuthType = isAuthType(authTypeOverride)

    return {
      appDomainType,
      userType,
      forceAuthType,
      authType: forceAuthType ? authTypeOverride : authType,
      authValueType: authValueType ?? 'email',
      companyId,
      ssoUrl,
    }
  }, [appDomainType, userType, authTypeOverride, authType, authValueType, companyId, ssoUrl])

  return <LoginInfoContext.Provider value={value}>{children}</LoginInfoContext.Provider>
}
