/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const internalBorrowersList: OperationData<Types.InternalBorrowersList> = {
  method: 'get',
  name: 'internalBorrowersList',
  summary: `Get borrowers`,
  path: '/companies/{companyId}/people',
  queryKey: ({ companyId }) => ['companies', companyId, 'people'],
}

export const internalBorrowerCreate: OperationData<Types.InternalBorrowerCreate> = {
  method: 'post',
  name: 'internalBorrowerCreate',
  summary: `Create borrower`,
  path: '/companies/{companyId}/people',
  queryKey: ({ companyId }) => ['companies', companyId, 'people'],
}

export const adminBorrowersReindex: OperationData<Types.AdminBorrowersReindex> = {
  method: 'post',
  name: 'adminBorrowersReindex',
  summary: `Recreate search index for borrowers`,
  path: '/companies/{companyId}/people/index-docs',
  queryKey: ({ companyId }) => ['companies', companyId, 'people', 'index-docs'],
}

export const internalBorrowersListIds: OperationData<Types.InternalBorrowersListIds> = {
  method: 'get',
  name: 'internalBorrowersListIds',
  summary: `Get borrowers`,
  path: '/companies/{companyId}/people/ids',
  queryKey: ({ companyId }) => ['companies', companyId, 'people', 'ids'],
}

export const internalBorrowerGet: OperationData<Types.InternalBorrowerGet> = {
  method: 'get',
  name: 'internalBorrowerGet',
  summary: `Get borrowers by ID`,
  path: '/companies/{companyId}/people/{personId}',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId],
}

export const internalBorrowerUpdate: OperationData<Types.InternalBorrowerUpdate> = {
  method: 'put',
  name: 'internalBorrowerUpdate',
  summary: `Update borrower`,
  path: '/companies/{companyId}/people/{personId}',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId],
}

export const adminGetBorrowerIndex: OperationData<Types.AdminGetBorrowerIndex> = {
  method: 'get',
  name: 'adminGetBorrowerIndex',
  summary: `Get a borrower's search index docs`,
  path: '/companies/{companyId}/people/{personId}/index-docs',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'index-docs'],
}

export const adminBorrowerReindex: OperationData<Types.AdminBorrowerReindex> = {
  method: 'post',
  name: 'adminBorrowerReindex',
  summary: `Recreate a borrower's search index doc`,
  path: '/companies/{companyId}/people/{personId}/index-docs',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'index-docs'],
}

export const internalBorrowerSearch: OperationData<Types.InternalBorrowerSearch> = {
  method: 'get',
  name: 'internalBorrowerSearch',
  summary: `Search people`,
  path: '/companies/{companyId}/people/search',
  queryKey: ({ companyId }) => ['companies', companyId, 'people', 'search'],
}

export const borrowersList: OperationData<Types.BorrowersList> = {
  method: 'get',
  name: 'borrowersList',
  summary: `Get borrowers`,
  path: '/people',
  queryKey: ({}) => ['people'],
}

export const borrowerCreate: OperationData<Types.BorrowerCreate> = {
  method: 'post',
  name: 'borrowerCreate',
  summary: `Create borrower`,
  path: '/people',
  queryKey: ({}) => ['people'],
}

export const borrowerGet: OperationData<Types.BorrowerGet> = {
  method: 'get',
  name: 'borrowerGet',
  summary: `Get borrower by ID`,
  path: '/people/{personId}',
  queryKey: ({ personId }) => ['people', personId],
}

export const borrowerUpdate: OperationData<Types.BorrowerUpdate> = {
  method: 'put',
  name: 'borrowerUpdate',
  summary: `Update borrower`,
  path: '/people/{personId}',
  queryKey: ({ personId }) => ['people', personId],
}

export const borrowerSearch: OperationData<Types.BorrowerSearch> = {
  method: 'get',
  name: 'borrowerSearch',
  summary: `Search borrowers`,
  path: '/people/search',
  queryKey: ({}) => ['people', 'search'],
}

export const internalGetPrimaryIdentity: OperationData<Types.InternalGetPrimaryIdentity> = {
  method: 'get',
  name: 'internalGetPrimaryIdentity',
  summary: `Get primary identity`,
  path: '/companies/{companyId}/people/{personId}/identity',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'identity'],
}

export const internalListIdentities: OperationData<Types.InternalListIdentities> = {
  method: 'get',
  name: 'internalListIdentities',
  summary: `Get identities`,
  path: '/companies/{companyId}/people/{personId}/identities',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'identities'],
}

export const internalCreateIdentity: OperationData<Types.InternalCreateIdentity> = {
  method: 'post',
  name: 'internalCreateIdentity',
  summary: `Create identity`,
  path: '/companies/{companyId}/people/{personId}/identities',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'identities'],
}

export const internalGetIdentity: OperationData<Types.InternalGetIdentity> = {
  method: 'get',
  name: 'internalGetIdentity',
  summary: `Get identity by ID`,
  path: '/companies/{companyId}/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ companyId, personId, personIdentityId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'identities',
    personIdentityId,
  ],
}

export const internalUpdateIdentity: OperationData<Types.InternalUpdateIdentity> = {
  method: 'put',
  name: 'internalUpdateIdentity',
  summary: `Update identity`,
  path: '/companies/{companyId}/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ companyId, personId, personIdentityId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'identities',
    personIdentityId,
  ],
}

export const internalDeleteIdentity: OperationData<Types.InternalDeleteIdentity> = {
  method: 'delete',
  name: 'internalDeleteIdentity',
  summary: `Archive identity`,
  path: '/companies/{companyId}/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ companyId, personId, personIdentityId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'identities',
    personIdentityId,
  ],
}

export const getPrimaryIdentity: OperationData<Types.GetPrimaryIdentity> = {
  method: 'get',
  name: 'getPrimaryIdentity',
  summary: `Get primary identity`,
  path: '/people/{personId}/identity',
  queryKey: ({ personId }) => ['people', personId, 'identity'],
}

export const listIdentities: OperationData<Types.ListIdentities> = {
  method: 'get',
  name: 'listIdentities',
  summary: `Get identities`,
  path: '/people/{personId}/identities',
  queryKey: ({ personId }) => ['people', personId, 'identities'],
}

export const createIdentity: OperationData<Types.CreateIdentity> = {
  method: 'post',
  name: 'createIdentity',
  summary: `Create identity`,
  path: '/people/{personId}/identities',
  queryKey: ({ personId }) => ['people', personId, 'identities'],
}

export const getIdentity: OperationData<Types.GetIdentity> = {
  method: 'get',
  name: 'getIdentity',
  summary: `Get identity by ID`,
  path: '/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ personId, personIdentityId }) => ['people', personId, 'identities', personIdentityId],
}

export const updateIdentity: OperationData<Types.UpdateIdentity> = {
  method: 'put',
  name: 'updateIdentity',
  summary: `Update identity`,
  path: '/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ personId, personIdentityId }) => ['people', personId, 'identities', personIdentityId],
}

export const deleteIdentity: OperationData<Types.DeleteIdentity> = {
  method: 'delete',
  name: 'deleteIdentity',
  summary: `Archive identity`,
  path: '/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ personId, personIdentityId }) => ['people', personId, 'identities', personIdentityId],
}

export const canInteract: OperationData<Types.CanInteract> = {
  method: 'post',
  name: 'canInteract',
  summary: `Can interact`,
  path: '/people/{personId}/can-interact',
  queryKey: ({ personId }) => ['people', personId, 'can-interact'],
}

export const internalCanInteract: OperationData<Types.InternalCanInteract> = {
  method: 'post',
  name: 'internalCanInteract',
  summary: `Can interact`,
  path: '/companies/{companyId}/people/{personId}/can-interact',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'can-interact'],
}
