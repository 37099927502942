// Error import: keep at top
import './polyfill'
import 'core/system/ErrorLogging'
import 'index.scss'
import 'core/hooks/useLog'
import { createRoot } from 'react-dom/client'

import { getOverride } from 'core/components/PeachOverrides/storage'
import Root from 'core/SystemProvider/Root'

if (import.meta.env.VITE_ENABLE_API_MOCKING && getOverride('enableApiMocking')) {
  void import('core/components/PeachOverrides/msw').then((lib) => lib.start())
}

createRoot(document.getElementById('root')!).render(<Root />)
