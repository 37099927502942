import { ChangeEvent, ChangeEventHandler, PropsWithChildren, ReactNode, Ref, forwardRef } from 'react'

import { Amount, CustomMaskedInput, Container, SubLabel, RadioInput, RadioLabel } from './RadioStyles'

type RadioProps = PropsWithChildren<{
  amount?: string | number
  bare?: boolean
  checked?: boolean
  customField?: boolean
  customFieldPlaceholder?: string | number
  customInputRef?: Ref<HTMLInputElement>
  disabled?: boolean
  getCustomAmount?: (value: string) => void
  id?: string
  label?: string
  name?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  subLabel?: ReactNode
  value?: string | number
  width?: string
}>

/** @deprecated Use Radio from core/components */
const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      amount,
      bare,
      checked,
      children,
      customField,
      customFieldPlaceholder,
      customInputRef,
      disabled,
      getCustomAmount,
      id,
      label,
      name,
      onChange,
      subLabel,
      value,
      width,
    },
    ref,
  ) => {
    return (
      <Container className='radio'>
        <RadioInput
          name={name || label}
          id={id || label}
          value={value}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          ref={ref}
        />
        {bare ?
          <RadioLabel data-testid='label-bare' width={width} htmlFor={id || label}>
            {'\u00A0'}
          </RadioLabel>
        : children ?
          <RadioLabel data-testid='label-with-children' width={width} htmlFor={id || label}>
            {children}
          </RadioLabel>
        : !customField ?
          <>
            <RadioLabel data-testid='label-no-custom-field' htmlFor={id || label} width={width}>
              {label}
            </RadioLabel>
            {subLabel && <SubLabel>{subLabel}</SubLabel>}
            {amount && <Amount>{amount}</Amount>}
          </>
        : <>
            <RadioLabel htmlFor={id || label} width={width}>
              {label}
            </RadioLabel>
            <CustomMaskedInput
              customInputRef={customInputRef}
              inputType='amount'
              onChange={(e: ChangeEvent<HTMLInputElement>) => getCustomAmount?.(e.target.value)}
              placeholder={customFieldPlaceholder}
            />
          </>
        }
      </Container>
    )
  },
)

Radio.displayName = 'Radio'

export default Radio
