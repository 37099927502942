import PropTypes from 'prop-types'
import { styled } from 'styled-components'

import { Spinner } from 'core/components'
import variables from 'core/styles/variables'

const RADIUS = '8px'

const Wrapper = styled.div``

const SubmitButton = styled.button`
  justify-content: center;
  transition: opacity 0.2s;
  border: none;
  border-radius: ${RADIUS};
  border-bottom-left-radius: ${(p) => (p.hasError ? '0' : RADIUS)};
  border-bottom-right-radius: ${(p) => (p.hasError ? '0' : RADIUS)};
  background-color: ${(p) => p.theme.primary};
  cursor: pointer;
  width: 100%;
  height: 64px;
  color: ${variables.colorWhite};
  font-size: 20px;
  font-weight: 500;

  &:disabled {
    background-color: ${variables.colorBlack40};
    cursor: default;
  }

  &:hover,
  &:focus {
    opacity: 0.95;
  }
`

const Subtitle = styled.div`
  padding: 32px;
  text-align: center;
  color: ${variables.colorBlack70};
`

const ErrorMessage = styled.div`
  border-bottom-left-radius: ${RADIUS};
  border-bottom-right-radius: ${RADIUS};
  background-color: ${variables.colorRedLighten};
  padding: 8px 24px;
  color: ${variables.colorBlack80};
`

const LoginSubmitButton = (props) => {
  const { onSubmit, errorMessage, submitLabel, subtitle, sending, disabled, ...rest } = props

  const displayLabel = sending ? <Spinner /> : submitLabel || 'Continue'

  const isDisabled = errorMessage || disabled

  return (
    <Wrapper>
      <SubmitButton onClick={onSubmit} disabled={isDisabled} hasError={!!errorMessage} {...rest}>
        {displayLabel}
      </SubmitButton>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  )
}

LoginSubmitButton.propTypes = {
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  sending: PropTypes.bool,
  subtitle: PropTypes.node,
  errorMessage: PropTypes.node,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
}

export default LoginSubmitButton
