import { FC } from 'react'

import { styled } from 'styled-components'

import APIendpoint, { isCustomEndpoint } from 'core/actions/helpers/APIendpoint'
import { variables } from 'core/styles'

import PeachOverrides from './PeachOverrides'
import { overridesEnabled } from './storage'

const env = isCustomEndpoint ? 'unknown' : (import.meta.env.VITE_ENV_LABEL ?? 'unknown')

const Badge: FC = () =>
  import.meta.env.VITE_ENV_LABEL_HIDE === 'true' ?
    null
  : <PeachOverrides>
      <StyledBadgeButton
        title={`API Base: ${APIendpoint}`}
        disabled={!overridesEnabled}
        $backgroundColor={
          env === 'local' ? variables.colorGreenLighten
          : env === 'dev' ?
            variables.colorBlueLighten
          : env === 'sandbox' ?
            variables.colorOrangeLighten
          : variables.colorRedLighten
        }
        $color={
          env === 'local' ? variables.colorGreen
          : env === 'dev' ?
            variables.colorBluePrimary
          : env === 'sandbox' ?
            variables.colorOrange
          : variables.colorRed
        }
      >
        {env}
      </StyledBadgeButton>
    </PeachOverrides>

export default Badge

const StyledBadgeButton = styled.button<{ $color: string; $backgroundColor: string }>`
  position: fixed;
  right: 6px;
  bottom: 6px;
  opacity: 0.8;
  border: 1px solid ${(p) => p.$color};
  border-radius: 3px;
  background-color: ${(p) => p.$backgroundColor};
  cursor: pointer;
  padding: 4px 8px;
  line-height: 1;
  white-space: nowrap;
  color: ${(p) => p.$color};
  font-size: 12px;

  &:disabled {
    cursor: default;
  }
`
