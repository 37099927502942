import { FC, useEffect, useMemo, useState } from 'react'

import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist'
import worker from 'pdfjs-dist/build/pdf.worker.mjs?worker&url'
import { EventBus, PDFViewer, PDFLinkService, PDFFindController, DownloadManager } from 'pdfjs-dist/web/pdf_viewer.mjs'
import 'pdfjs-dist/web/pdf_viewer.css'
import { useUpdate } from 'react-use'
import { base64 } from 'rfc4648'
import { styled } from 'styled-components'

import Spinner from 'core/components/lib/Spinner'
import { R } from 'core/helpers'
import { variables } from 'core/styles'

import Toolbar from './Toolbar'

GlobalWorkerOptions.workerSrc = worker

const maybeDecode = (data: string) => {
  try {
    return base64.parse(data).buffer
  } catch {
    return data
  }
}

type PeachPDFViewerProps = {
  url?: string
  data?: string | Blob
  filename?: string
  maximized?: boolean
}

const PeachPDFViewer: FC<PeachPDFViewerProps> = ({ url, data, filename = 'file.pdf', maximized = false }) => {
  const [isLoading, setLoading] = useState(true)
  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  const update = useUpdate()

  const pdfViewer = useMemo(() => {
    if (!container) return

    const eventBus = new EventBus()
    const linkService = new PDFLinkService({ eventBus })
    const pdfViewer = new PDFViewer({
      container,
      eventBus,
      linkService,
      findController: new PDFFindController({ eventBus, linkService }),
      downloadManager: new DownloadManager(),
    })
    linkService.setViewer(pdfViewer)

    eventBus.on('pagesloaded', () => setLoading(false))
    eventBus.on('pagerendered', update)

    return pdfViewer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container])

  useEffect(() => {
    if (!pdfViewer) return

    void (async () => {
      const doc = await getDocument({
        url,
        data: R.isString(data) ? maybeDecode(data) : await data?.arrayBuffer(),
        cMapUrl: '/cmaps',
        cMapPacked: true,
      }).promise

      pdfViewer.setDocument(doc)
      ;(pdfViewer.linkService as PDFLinkService)?.setDocument(doc)
      pdfViewer.findController?.setDocument(doc)
    })()
  }, [pdfViewer, data, url])

  return (
    <Container>
      {isLoading || !pdfViewer ?
        <StyledSpinner />
      : <Toolbar pdfViewer={pdfViewer} filename={filename} />}

      <Wrapper $height={maximized && container ? Math.max(container.scrollHeight, 358) : 358}>
        <div ref={setContainer}>
          <div />
        </div>
      </Wrapper>
    </Container>
  )
}

export default PeachPDFViewer

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: column;
  border-bottom: 3px solid ${variables.colorBlack90};
  background-color: ${variables.colorBlack90};
  min-height: 400px;
`

const Wrapper = styled.div<{ $height: number }>`
  position: relative;
  height: ${(p) => p.$height}px;

  > div {
    position: absolute;
    inset: 0;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: ${variables.colorBlack60} ${variables.colorBlack90};

    .page {
      position: relative;
      margin: 16px auto;
    }

    /* stylelint-disable selector-class-pattern */
    .textLayer span,
    .textLayer p {
      line-height: 1;
    }
  }
`

const StyledSpinner = styled(Spinner)`
  position: absolute;
  inset: 0;
  z-index: 9;
  background-color: ${variables.colorBlack90};
`
