import './index.scss'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import MaskedInput from 'core/components/MaskedInput'
import variables from 'core/styles/variables'

const AnimatedInput = (props) => {
  const {
    label,
    maskedType,
    name,
    onChange,
    onEnter,
    onKeyPress,
    placeholder,
    readOnly,
    disabled,
    selfError,
    showError,
    type,
    value,
  } = props

  const componentClass = classNames('material-styled-animation', {
    borderless: props.borderless,
    'error-styles': props.showError,
  })

  const handleKeyPress = (event) => {
    if (onEnter && event.key === 'Enter') onEnter(event)
    if (onKeyPress) onKeyPress(event)
  }

  const labelColor = selfError || showError ? variables.colorRed : variables.colorBlack50

  return (
    <label className={componentClass}>
      {maskedType ?
        <MaskedInput
          inputType={maskedType}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={readOnly || disabled}
          unstyled
        />
      : <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={readOnly || disabled}
          onKeyPress={handleKeyPress}
        />
      }
      <span style={{ color: labelColor }}>{label}</span>
    </label>
  )
}

AnimatedInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  borderless: PropTypes.bool,
  maskedType: PropTypes.oneOf(['cardNumber', 'zipCode', 'expDate', 'cvc']),
  showError: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  selfError: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onEnter: PropTypes.func,
}

export default AnimatedInput
