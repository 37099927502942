import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { styled, css } from 'styled-components'

import variables from 'core/styles/variables'

const InnerContainer = styled.section`
  box-sizing: border-box;
  border-radius: 8px;

  & + & {
    margin-top: 16px;
  }

  ${(p) =>
    p.borderless ?
      css`
        border: none;
        overflow: visible;
      `
    : css`
        border: 1px solid ${variables.colorBlack20};
      `}

  ${(p) =>
    p.hasTopCap &&
    css`
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

  ${(p) =>
    p.hasBottomCap &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: transparent;
    `}
`

const AgentTopCap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${variables.colorBlack90};
  padding: 4px 0;
  min-height: 16px;
  color: white;
  font-size: 14px;
`

const InfoCardContainer = (props) => {
  const { agent, bottomCap, customClassName, borderless, style, children } = props
  const { pathname } = useLocation()

  const isCrm = pathname.includes('crm')

  return (
    <>
      {agent && <AgentTopCap>Agent-only Screen</AgentTopCap>}

      <InnerContainer
        className={customClassName}
        style={style}
        borderless={borderless || isCrm}
        hasTopCap={agent}
        hasBottomCap={!!bottomCap}
      >
        {children}
      </InnerContainer>

      {bottomCap}
    </>
  )
}

InfoCardContainer.propTypes = {
  agent: PropTypes.bool,
  customClassName: PropTypes.string,
  borderless: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node,
  bottomCap: PropTypes.node,
}

export { InnerContainer, AgentTopCap }
export default InfoCardContainer
