import { styled } from 'styled-components'

import { SelectOption } from 'core/components/lib/Select'
import { R } from 'core/helpers'
import { Field } from 'core/hooks/useForm'

import Checkbox from './Checkbox'

type CheckboxGroupProps<T extends string | number> = Field<Array<T>> & {
  className?: string
  /** A list of label and value pairs corresponding to the checkboxes to display. */
  options: Array<SelectOption<T>>
  /** Include a checkbox to check or uncheck all values at once. */
  includeAll?: boolean
}

/** A column of checkboxes with optional "All" checkbox. Avoids clobering `value`. */
const CheckboxGroup = <T extends string | number>({
  className,
  options,
  includeAll,
  name,
  value,
  disabled,
  onChange,
}: CheckboxGroupProps<T>) => (
  <Column className={className}>
    {includeAll && (
      <Checkbox
        label='All'
        name={`${name?.toString()}-all`}
        value={options.every((opt) => value.includes(opt.value))}
        disabled={disabled || options.every(R.prop('isDisabled'))}
        onChange={(checked) =>
          onChange?.(
            checked ?
              R.pipe(
                options,
                R.reject((opt) => !!opt.isDisabled),
                R.map(R.prop('value')),
                R.concat(value),
                R.uniq(),
              )
            : R.differenceWith(value, options, (val, opt) => opt.value === val),
          )
        }
      />
    )}
    {R.map(options, (option) => (
      <StyledCheckbox
        key={option.value}
        label={option.label}
        sublabel={option.sublabel}
        name={`${name?.toString()}-${option.value}`}
        value={value.includes(option.value)}
        disabled={disabled || option.isDisabled}
        onChange={(checked) =>
          onChange?.(
            checked ? R.uniq([...(value ?? []), option.value]) : R.reject(value ?? [], (v) => v === option.value),
          )
        }
        $margin={includeAll ? '0 0 0 12px' : '0'}
      />
    ))}
  </Column>
)

export default CheckboxGroup

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledCheckbox = styled(Checkbox)<{ $margin?: string }>`
  margin: ${(p) => p.$margin ?? '0'};
`
