import qs from 'qs'
import * as R from 'remeda'

import { PEACH_UTM, getQueryUTM } from 'core/actions/helpers/tokenHelpers'

const MINUTE = 60 * 1000
const HOUR = 60 * MINUTE

const key = 'LOGIN_REDIRECT_PATH'

const TIME_LIMIT = 24 * HOUR

const retrieve = (key) => {
  const item = localStorage.getItem(key)

  if (!key || !item) return undefined

  try {
    return JSON.parse(item)
  } catch {
    return undefined
  }
}

const store = (key, value) => {
  if (!key) return
  try {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.removeItem(key)
    }
  } catch {
    // Ignore error
  }
}

const getPath = () => {
  try {
    return window.location.href.substring(window.location.origin.length)
  } catch {
    return window.location.pathname
  }
}

const goTo = (path) => {
  const currentPath = window.location.pathname
  const _path = path || '/'
  if (currentPath !== _path) {
    window.location.href = _path
  }
}

const getLoginRedirectPath = () => {
  const now = Date.now()
  const { path, setAt } = retrieve(key) || {}
  store(key, null)

  if (path && setAt + TIME_LIMIT > now) {
    return path
  }
}

const setLoginRedirectPath = () => {
  const path = getPath()

  if (path) {
    store(key, { path, setAt: Date.now() })
  } else {
    store(key, null)
  }
}

const LOGOUT_KEYS = ['personIdFromToken', 'secretToken', 'userTypeFromToken', 'username', 'storedResourceList']

const clearSavedSession = () => {
  R.forEach(LOGOUT_KEYS, (key) => localStorage.removeItem(key))
  R.forEach([PEACH_UTM], (key) => sessionStorage.removeItem(key))
}

// public login methods

const redirectToApp = () => {
  goTo(getLoginRedirectPath())
}

const redirectToLogin = () => {
  const utm = getQueryUTM()

  clearSavedSession()
  setLoginRedirectPath()
  goTo(`/login${R.isNil(utm) ? '' : qs.stringify(utm, { addQueryPrefix: true })}`)
}

const signOut = () => {
  clearSavedSession()
  store(key, null)
  goTo('/login')
}

export { redirectToApp, redirectToLogin, signOut }
