/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const getWorkflowTypes: OperationData<Types.GetWorkflowTypes> = {
  method: 'get',
  name: 'getWorkflowTypes',
  summary: `Get workflow types`,
  path: '/companies/{companyId}/workflow-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'workflow-types'],
}

export const createWorkflowType: OperationData<Types.CreateWorkflowType> = {
  method: 'post',
  name: 'createWorkflowType',
  summary: `Create workflow type`,
  path: '/companies/{companyId}/workflow-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'workflow-types'],
}

export const getWorkflowTypeById: OperationData<Types.GetWorkflowTypeById> = {
  method: 'get',
  name: 'getWorkflowTypeById',
  summary: `Get workflow type by ID`,
  path: '/companies/{companyId}/workflow-types/{workflowTypeId}',
  queryKey: ({ companyId, workflowTypeId }) => ['companies', companyId, 'workflow-types', workflowTypeId],
}

export const updateWorkflowType: OperationData<Types.UpdateWorkflowType> = {
  method: 'put',
  name: 'updateWorkflowType',
  summary: `Update workflow type`,
  path: '/companies/{companyId}/workflow-types/{workflowTypeId}',
  queryKey: ({ companyId, workflowTypeId }) => ['companies', companyId, 'workflow-types', workflowTypeId],
}

export const deleteWorkflowType: OperationData<Types.DeleteWorkflowType> = {
  method: 'delete',
  name: 'deleteWorkflowType',
  summary: `Delete workflow type`,
  path: '/companies/{companyId}/workflow-types/{workflowTypeId}',
  queryKey: ({ companyId, workflowTypeId }) => ['companies', companyId, 'workflow-types', workflowTypeId],
}

export const getWorkflowStepTypes: OperationData<Types.GetWorkflowStepTypes> = {
  method: 'get',
  name: 'getWorkflowStepTypes',
  summary: `Get workflow step types`,
  path: '/companies/{companyId}/workflow-step-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'workflow-step-types'],
}

export const createWorkflowStepType: OperationData<Types.CreateWorkflowStepType> = {
  method: 'post',
  name: 'createWorkflowStepType',
  summary: `Create workflow step type`,
  path: '/companies/{companyId}/workflow-step-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'workflow-step-types'],
}

export const getWorkflowStepTypeById: OperationData<Types.GetWorkflowStepTypeById> = {
  method: 'get',
  name: 'getWorkflowStepTypeById',
  summary: `Get workflow step type`,
  path: '/companies/{companyId}/workflow-step-types/{workflowStepTypeId}',
  queryKey: ({ companyId, workflowStepTypeId }) => ['companies', companyId, 'workflow-step-types', workflowStepTypeId],
}

export const updateWorkflowStepType: OperationData<Types.UpdateWorkflowStepType> = {
  method: 'put',
  name: 'updateWorkflowStepType',
  summary: `Update workflow step type`,
  path: '/companies/{companyId}/workflow-step-types/{workflowStepTypeId}',
  queryKey: ({ companyId, workflowStepTypeId }) => ['companies', companyId, 'workflow-step-types', workflowStepTypeId],
}

export const deleteWorkflowStepType: OperationData<Types.DeleteWorkflowStepType> = {
  method: 'delete',
  name: 'deleteWorkflowStepType',
  summary: `Delete workflow step type`,
  path: '/companies/{companyId}/workflow-step-types/{workflowStepTypeId}',
  queryKey: ({ companyId, workflowStepTypeId }) => ['companies', companyId, 'workflow-step-types', workflowStepTypeId],
}

export const getWorkitemTypes: OperationData<Types.GetWorkitemTypes> = {
  method: 'get',
  name: 'getWorkitemTypes',
  summary: `Get work item types`,
  path: '/companies/{companyId}/workflow-workitem-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'workflow-workitem-types'],
}

export const createWorkitemType: OperationData<Types.CreateWorkitemType> = {
  method: 'post',
  name: 'createWorkitemType',
  summary: `Create work item type`,
  path: '/companies/{companyId}/workflow-workitem-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'workflow-workitem-types'],
}

export const getWorkitemTypeById: OperationData<Types.GetWorkitemTypeById> = {
  method: 'get',
  name: 'getWorkitemTypeById',
  summary: `Get work item type`,
  path: '/companies/{companyId}/workflow-workitem-types/{workflowWorkitemTypeId}',
  queryKey: ({ companyId, workflowWorkitemTypeId }) => [
    'companies',
    companyId,
    'workflow-workitem-types',
    workflowWorkitemTypeId,
  ],
}

export const updateWorkitemType: OperationData<Types.UpdateWorkitemType> = {
  method: 'put',
  name: 'updateWorkitemType',
  summary: `Update work item type`,
  path: '/companies/{companyId}/workflow-workitem-types/{workflowWorkitemTypeId}',
  queryKey: ({ companyId, workflowWorkitemTypeId }) => [
    'companies',
    companyId,
    'workflow-workitem-types',
    workflowWorkitemTypeId,
  ],
}

export const deleteWorkitemType: OperationData<Types.DeleteWorkitemType> = {
  method: 'delete',
  name: 'deleteWorkitemType',
  summary: `Delete work item type`,
  path: '/companies/{companyId}/workflow-workitem-types/{workflowWorkitemTypeId}',
  queryKey: ({ companyId, workflowWorkitemTypeId }) => [
    'companies',
    companyId,
    'workflow-workitem-types',
    workflowWorkitemTypeId,
  ],
}

export const getCaseWorkflow: OperationData<Types.GetCaseWorkflow> = {
  method: 'get',
  name: 'getCaseWorkflow',
  summary: `Get case workflow`,
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}/workflow',
  queryKey: ({ companyId, personId, caseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    'workflow',
  ],
}

export const updateWorkflowStep: OperationData<Types.UpdateWorkflowStep> = {
  method: 'put',
  name: 'updateWorkflowStep',
  summary: `Update workflow step status`,
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}/workflow/steps/{workflowStepId}',
  queryKey: ({ companyId, personId, caseId, workflowStepId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    'workflow',
    'steps',
    workflowStepId,
  ],
}

export const updateWorkflowWorkitem: OperationData<Types.UpdateWorkflowWorkitem> = {
  method: 'put',
  name: 'updateWorkflowWorkitem',
  summary: `Update workflow workitem status`,
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}/workflow/steps/{workflowStepId}/workitems/{workflowWorkitemId}',
  queryKey: ({ companyId, personId, caseId, workflowStepId, workflowWorkitemId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    'workflow',
    'steps',
    workflowStepId,
    'workitems',
    workflowWorkitemId,
  ],
}

export const exportWorkflowTypes: OperationData<Types.ExportWorkflowTypes> = {
  method: 'post',
  name: 'exportWorkflowTypes',
  summary: `Export workflow, step and work item types.`,
  path: '/companies/{companyId}/workflow-types/export',
  queryKey: ({ companyId }) => ['companies', companyId, 'workflow-types', 'export'],
}

export const importWorkflowTypes: OperationData<Types.ImportWorkflowTypes> = {
  method: 'post',
  name: 'importWorkflowTypes',
  summary: `Restore previously exported workflow, step and work item types.`,
  path: '/companies/{companyId}/workflow-types/import',
  queryKey: ({ companyId }) => ['companies', companyId, 'workflow-types', 'import'],
}
