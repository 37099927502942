import { ReactNode, useEffect } from 'react'

import ReactSelect, { GroupBase, OnChangeValue, Props as ReactSelectProps } from 'react-select'
import * as R from 'remeda'
import { styled } from 'styled-components'

import customStyles from 'core/components/legacy/Select/customStyles'
import { useDebounce, PendingEventHandler, usePendingCallback } from 'core/hooks'

import { DropdownIndicator, MenuList, Option, SelectOption, ValueContainer } from './components'

export type CommonProps<IsMulti extends boolean, Value> = Omit<
  ReactSelectProps<SelectOption<Value>, IsMulti, GroupBase<SelectOption<Value>>>,
  'onChange' | 'isDisabled' | 'onBlur' | 'styles' | 'defaultValue'
> & {
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  fetchNextPage?: () => void
  disabled?: boolean
  className?: string
  width?: string
  prefix?: ReactNode
}

export type BaseSelectProps<IsMulti extends boolean, Value> = CommonProps<IsMulti, Value> & {
  onChange?: PendingEventHandler<OnChangeValue<SelectOption<Value>, IsMulti> | null>
}

const BaseSelect = <IsMulti extends boolean, Value = string>({
  name,
  value,
  disabled,
  onChange: rawOnChange,
  className,
  width,
  options,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  onMenuScrollToBottom,
  onInputChange: rawOnInputChange,
  ...props
}: BaseSelectProps<IsMulti, Value>) => {
  const onInputChange = useDebounce(rawOnInputChange ?? R.noop, 500)

  useEffect(() => {
    if (!isLoading && value === undefined) {
      void rawOnChange?.(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const [isPending, onChange] = usePendingCallback((newValue: OnChangeValue<SelectOption<Value>, IsMulti>) =>
    rawOnChange?.(newValue),
  )

  return (
    <Box className={className} width={width}>
      <ReactSelect
        components={{
          DropdownIndicator,
          MenuList,
          Option,
          ValueContainer,
        }}
        styles={customStyles}
        options={options}
        isDisabled={isPending || isLoading || disabled}
        onChange={onChange}
        menuPlacement='auto'
        isLoading={isPending || isFetchingNextPage || isLoading}
        value={value}
        onMenuScrollToBottom={(e) => {
          if (!isFetchingNextPage && props.hasNextPage) {
            fetchNextPage?.()
          }

          onMenuScrollToBottom?.(e)
        }}
        captureMenuScroll
        isSearchable={!fetchNextPage || !!rawOnInputChange}
        onInputChange={onInputChange}
        filterOption={rawOnInputChange ? () => true : undefined}
        loadingMessage={() => <span />}
        {...props}
      />
    </Box>
  )
}

export default BaseSelect

const Box = styled.div<{ width?: string }>`
  width: ${(p) => p.width ?? 'initial'};
`
