import { FC, PropsWithChildren, createContext, useContext } from 'react'

import { getOverride } from 'core/components/PeachOverrides/storage'

const UserTypeContext = createContext<'agent' | 'borrower'>('borrower')

type UserTypeProviderProps = PropsWithChildren<{ userType: 'agent' | 'borrower' }>

const UserTypeProvider: FC<UserTypeProviderProps> = ({ userType, children }) => (
  <UserTypeContext.Provider value={(getOverride('userType') as 'agent' | 'borrower') ?? userType}>
    {children}
  </UserTypeContext.Provider>
)

const useUserType = () => useContext(UserTypeContext)

export { UserTypeProvider, useUserType }
