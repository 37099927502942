import { styled } from 'styled-components'

import { useCompanyCopies } from 'core/hooks'
import variables from 'core/styles/variables'

const Disclosures = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  padding-bottom: 16px;
`

const Content = styled.div`
  width: 100%;
  line-height: 24px;
  white-space: pre-wrap;
  color: ${variables.colorBlack70};
  font-size: 12px;

  a {
    text-decoration: none;
    color: ${variables.colorBluePrimary};

    &:hover {
      text-decoration: underline;
    }
  }
`

const FooterDisclosures = () => {
  const { disclosures } = useCompanyCopies()

  const disclosuresHtml = { __html: disclosures }

  return <Disclosures>{disclosures && <Content dangerouslySetInnerHTML={disclosuresHtml} />}</Disclosures>
}

export default FooterDisclosures
