// eslint-disable-next-line no-restricted-imports
import { useFlags as useFlagsBase } from 'launchdarkly-react-client-sdk'

import { getOverride } from 'core/components/PeachOverrides/storage'
import * as R from 'core/helpers/remeda'
import { featureFlags } from 'core/types'

const useFlags = () => {
  const flags = useFlagsBase<{ [_ in keyof typeof featureFlags]: boolean }>()

  return getOverride('enableFeatureFlagMocking') ?
      R.mapValues(flags, (value, key) => (getOverride(key) as boolean) ?? false)
    : flags
}

export default useFlags
