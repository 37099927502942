import { LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'
import { AppDispatch } from 'core/store'

interface Props {
  dispatch: AppDispatch // The actual dispatch function returned from useDispatch()
  key?: string // The string that corresponds to the loading key
  filters?: { [key: string]: unknown } // All filterable options (ie. personId)
  type: string // The dispatch type that corresponds to a specific reducer process
  url: string // The the url endpoint for the request (do not include api base)
  nextUrl?: string // The url of the next page in the data pagination, if it exists
  saveOptions?: { [key: string]: unknown } // The object which holds various key/value pairs to help the reducer handle the data more specifically
  collection?: Array<{ [key: string]: unknown }> // The array that holds the aggregate collection of data, especially if nextUrl is truthy
}

const getCollectionWithNextUrl = async (props: Props): Promise<Array<{ [key: string]: unknown }> | void> => {
  const { dispatch, key, type, url, nextUrl, saveOptions = {}, collection = [] } = props
  if (key && !nextUrl) {
    dispatch({ type: LOADING, key, status: 'loading' })
  }

  try {
    const response = await peachApi.get({
      url: !nextUrl ? url : null,
      fullUrl: nextUrl,
    })

    /**
     * Handle null data response case so we can safely spread data
     * into newCollection below
     */
    const responseData = response.data || []

    dispatch({
      type: nextUrl ? `${type}_NEXT_URL` : type,
      payload: responseData,
      ...saveOptions,
    })

    const newCollection = [...collection, ...responseData]

    if (response.nextUrl) {
      return getCollectionWithNextUrl({
        ...props,
        nextUrl: response.nextUrl,
        collection: newCollection,
      })
    } else {
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }

      /**
       * There are many places where useCollection('someCollection')
       * assigns the hook value to a variable so we need to make sure
       * to return the final aggregate value back so those instances
       * still work just the same.
       */
      return newCollection
    }
  } catch {
    if (key) {
      dispatch({ type: LOADING, key, status: 'error' })
    }
  }
}

export default getCollectionWithNextUrl
