/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const internalPromoProgramTypesGetAll: OperationData<Types.InternalPromoProgramTypesGetAll> = {
  method: 'get',
  name: 'internalPromoProgramTypesGetAll',
  summary: `Get promo program types`,
  path: '/companies/{companyId}/promo-program-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'promo-program-types'],
}

export const internalPromoProgramTypesAdd: OperationData<Types.InternalPromoProgramTypesAdd> = {
  method: 'post',
  name: 'internalPromoProgramTypesAdd',
  summary: `Create promo program type`,
  path: '/companies/{companyId}/promo-program-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'promo-program-types'],
}

export const internalPromoProgramTypesGet: OperationData<Types.InternalPromoProgramTypesGet> = {
  method: 'get',
  name: 'internalPromoProgramTypesGet',
  summary: `Get promo program type by ID`,
  path: '/companies/{companyId}/promo-program-types/{promoProgramTypeId}',
  queryKey: ({ companyId, promoProgramTypeId }) => ['companies', companyId, 'promo-program-types', promoProgramTypeId],
}

export const internalPromoProgramTypesUpdate: OperationData<Types.InternalPromoProgramTypesUpdate> = {
  method: 'put',
  name: 'internalPromoProgramTypesUpdate',
  summary: `Update promo program type`,
  path: '/companies/{companyId}/promo-program-types/{promoProgramTypeId}',
  queryKey: ({ companyId, promoProgramTypeId }) => ['companies', companyId, 'promo-program-types', promoProgramTypeId],
}

export const internalPromoProgramTypesDelete: OperationData<Types.InternalPromoProgramTypesDelete> = {
  method: 'delete',
  name: 'internalPromoProgramTypesDelete',
  summary: `Cancel promo program type`,
  path: '/companies/{companyId}/promo-program-types/{promoProgramTypeId}',
  queryKey: ({ companyId, promoProgramTypeId }) => ['companies', companyId, 'promo-program-types', promoProgramTypeId],
}

export const promoProgramsGetAll: OperationData<Types.PromoProgramsGetAll> = {
  method: 'get',
  name: 'promoProgramsGetAll',
  summary: `Get promo programs`,
  path: '/people/{personId}/loans/{loanId}/promo-programs',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'promo-programs'],
}

export const promoProgramsAdd: OperationData<Types.PromoProgramsAdd> = {
  method: 'post',
  name: 'promoProgramsAdd',
  summary: `Create promo program`,
  path: '/people/{personId}/loans/{loanId}/promo-programs',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'promo-programs'],
}

export const promoProgramsGet: OperationData<Types.PromoProgramsGet> = {
  method: 'get',
  name: 'promoProgramsGet',
  summary: `Get promo program by ID`,
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
  ],
}

export const promoProgramsUpdate: OperationData<Types.PromoProgramsUpdate> = {
  method: 'put',
  name: 'promoProgramsUpdate',
  summary: `Update promo program`,
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
  ],
}

export const promoProgramsDelete: OperationData<Types.PromoProgramsDelete> = {
  method: 'delete',
  name: 'promoProgramsDelete',
  summary: `Cancel promo program`,
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
  ],
}

export const promoProgramsActivate: OperationData<Types.PromoProgramsActivate> = {
  method: 'post',
  name: 'promoProgramsActivate',
  summary: `Activate promo program`,
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}/activate',
  queryKey: ({ personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    'activate',
  ],
}

export const promoProgramsDrawAdd: OperationData<Types.PromoProgramsDrawAdd> = {
  method: 'post',
  name: 'promoProgramsDrawAdd',
  summary: `Create promo program for draw`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/promo-programs',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'promo-programs'],
}

export const promoProgramsDrawGet: OperationData<Types.PromoProgramsDrawGet> = {
  method: 'get',
  name: 'promoProgramsDrawGet',
  summary: `Get promo program for draw by ID`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/promo-programs/{promoProgramId}',
  queryKey: ({ personId, loanId, drawId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'promo-programs',
    promoProgramId,
  ],
}

export const promoProgramsDrawUpdate: OperationData<Types.PromoProgramsDrawUpdate> = {
  method: 'put',
  name: 'promoProgramsDrawUpdate',
  summary: `Update promo program for draw`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/promo-programs/{promoProgramId}',
  queryKey: ({ personId, loanId, drawId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'promo-programs',
    promoProgramId,
  ],
}

export const promoProgramsDrawDelete: OperationData<Types.PromoProgramsDrawDelete> = {
  method: 'delete',
  name: 'promoProgramsDrawDelete',
  summary: `Cancel promo program for draw`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/promo-programs/{promoProgramId}',
  queryKey: ({ personId, loanId, drawId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'promo-programs',
    promoProgramId,
  ],
}

export const promoProgramsDrawActivate: OperationData<Types.PromoProgramsDrawActivate> = {
  method: 'post',
  name: 'promoProgramsDrawActivate',
  summary: `Activate promo program for draw`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/promo-programs/{promoProgramId}/activate',
  queryKey: ({ personId, loanId, drawId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'promo-programs',
    promoProgramId,
    'activate',
  ],
}

export const internalPromoProgramsExercise: OperationData<Types.InternalPromoProgramsExercise> = {
  method: 'post',
  name: 'internalPromoProgramsExercise',
  summary: `Daily check (and exercising) promo program`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/exercise',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    'exercise',
  ],
}

export const internalPromoProgramsReverse: OperationData<Types.InternalPromoProgramsReverse> = {
  method: 'post',
  name: 'internalPromoProgramsReverse',
  summary: `Reverse promo program`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}/reverse',
  queryKey: ({ companyId, personId, loanId, promoProgramId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    'reverse',
  ],
}

export const internalPromoProgramsGetAll: OperationData<Types.InternalPromoProgramsGetAll> = {
  method: 'get',
  name: 'internalPromoProgramsGetAll',
  summary: `Get promo programs`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
  ],
}

export const internalPromoProgramsGet: OperationData<Types.InternalPromoProgramsGet> = {
  method: 'get',
  name: 'internalPromoProgramsGet',
  summary: `Get promo program by ID`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ companyId, personId, loanId, promoProgramId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
  ],
}

export const internalActivePromoPrograms: OperationData<Types.InternalActivePromoPrograms> = {
  method: 'get',
  name: 'internalActivePromoPrograms',
  summary: `Get active promo programs`,
  path: '/promo-programs/active',
  queryKey: ({}) => ['promo-programs', 'active'],
}
