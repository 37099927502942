/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const pastPeriodsDataList: OperationData<Types.PastPeriodsDataList> = {
  method: 'get',
  name: 'pastPeriodsDataList',
  summary: `Get past period data`,
  path: '/people/{personId}/loans/{loanId}/migration/past-periods',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'past-periods'],
}

export const pastPeriodsDataUpdate: OperationData<Types.PastPeriodsDataUpdate> = {
  method: 'put',
  name: 'pastPeriodsDataUpdate',
  summary: `Update past period data`,
  path: '/people/{personId}/loans/{loanId}/migration/past-periods',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'past-periods'],
}

export const pastPeriodsDataCreate: OperationData<Types.PastPeriodsDataCreate> = {
  method: 'post',
  name: 'pastPeriodsDataCreate',
  summary: `Create past periods data`,
  path: '/people/{personId}/loans/{loanId}/migration/past-periods',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'past-periods'],
}

export const pastPeriodsDataDelete: OperationData<Types.PastPeriodsDataDelete> = {
  method: 'delete',
  name: 'pastPeriodsDataDelete',
  summary: `Delete past period data`,
  path: '/people/{personId}/loans/{loanId}/migration/past-periods',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'past-periods'],
}

export const pastTransactionDataGetAll: OperationData<Types.PastTransactionDataGetAll> = {
  method: 'get',
  name: 'pastTransactionDataGetAll',
  summary: `Get past transactions`,
  path: '/people/{personId}/loans/{loanId}/migration/past-transaction',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'past-transaction'],
}

export const pastTransactionDataCreate: OperationData<Types.PastTransactionDataCreate> = {
  method: 'post',
  name: 'pastTransactionDataCreate',
  summary: `Create past transaction`,
  path: '/people/{personId}/loans/{loanId}/migration/past-transaction',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'past-transaction'],
}

export const pastTransactionDataGetOne: OperationData<Types.PastTransactionDataGetOne> = {
  method: 'get',
  name: 'pastTransactionDataGetOne',
  summary: `Get past transaction by ID`,
  path: '/people/{personId}/loans/{loanId}/migration/past-transaction/{transactionId}',
  queryKey: ({ personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'migration',
    'past-transaction',
    transactionId,
  ],
}

export const pastTransactionDataUpdate: OperationData<Types.PastTransactionDataUpdate> = {
  method: 'put',
  name: 'pastTransactionDataUpdate',
  summary: `Update past transaction`,
  path: '/people/{personId}/loans/{loanId}/migration/past-transaction/{transactionId}',
  queryKey: ({ personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'migration',
    'past-transaction',
    transactionId,
  ],
}

export const migrationPeriodLocGet: OperationData<Types.MigrationPeriodLocGet> = {
  method: 'get',
  name: 'migrationPeriodLocGet',
  summary: `Get migration period LOC data`,
  path: '/people/{personId}/loans/{loanId}/migration/period',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'period'],
}

export const migrationPeriodLocUpdate: OperationData<Types.MigrationPeriodLocUpdate> = {
  method: 'put',
  name: 'migrationPeriodLocUpdate',
  summary: `Update migration period LOC data`,
  path: '/people/{personId}/loans/{loanId}/migration/period',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'period'],
}

export const migrationPeriodLocCreate: OperationData<Types.MigrationPeriodLocCreate> = {
  method: 'post',
  name: 'migrationPeriodLocCreate',
  summary: `Create migration period LOC data`,
  path: '/people/{personId}/loans/{loanId}/migration/period',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'period'],
}

export const migrationPeriodDrawGet: OperationData<Types.MigrationPeriodDrawGet> = {
  method: 'get',
  name: 'migrationPeriodDrawGet',
  summary: `Get migration period draw data`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/migration/period',
  queryKey: ({ personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'migration',
    'period',
  ],
}

export const migrationPeriodDrawUpdate: OperationData<Types.MigrationPeriodDrawUpdate> = {
  method: 'put',
  name: 'migrationPeriodDrawUpdate',
  summary: `Update migration period draw data`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/migration/period',
  queryKey: ({ personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'migration',
    'period',
  ],
}

export const migrationPeriodDrawCreate: OperationData<Types.MigrationPeriodDrawCreate> = {
  method: 'post',
  name: 'migrationPeriodDrawCreate',
  summary: `Create migration period draw data`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/migration/period',
  queryKey: ({ personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'migration',
    'period',
  ],
}
