/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const unboundInteractionGetAll: OperationData<Types.UnboundInteractionGetAll> = {
  method: 'get',
  name: 'unboundInteractionGetAll',
  summary: `Get interactions`,
  path: '/interactions',
  queryKey: ({}) => ['interactions'],
}

export const unboundInteractionGetById: OperationData<Types.UnboundInteractionGetById> = {
  method: 'get',
  name: 'unboundInteractionGetById',
  summary: `Get interaction by ID`,
  path: '/interactions/{interactionId}',
  queryKey: ({ interactionId }) => ['interactions', interactionId],
}

export const unboundInteractionDelete: OperationData<Types.UnboundInteractionDelete> = {
  method: 'delete',
  name: 'unboundInteractionDelete',
  summary: `Delete unbound interaction`,
  path: '/interactions/{interactionId}',
  queryKey: ({ interactionId }) => ['interactions', interactionId],
}

export const unboundGetManyPreviousInteractions: OperationData<Types.UnboundGetManyPreviousInteractions> = {
  method: 'get',
  name: 'unboundGetManyPreviousInteractions',
  summary: `Get previous interactions`,
  path: '/interactions/{interactionId}/previous',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'previous'],
}

export const interactionConvoGet: OperationData<Types.InteractionConvoGet> = {
  method: 'get',
  name: 'interactionConvoGet',
  summary: `Get interaction convo`,
  path: '/interactions/{interactionId}/convo',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'convo'],
}

export const interactionConvoJoin: OperationData<Types.InteractionConvoJoin> = {
  method: 'post',
  name: 'interactionConvoJoin',
  summary: `Join interaction convo`,
  path: '/interactions/{interactionId}/convo/join',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'convo', 'join'],
}

export const interactionConvoLeave: OperationData<Types.InteractionConvoLeave> = {
  method: 'post',
  name: 'interactionConvoLeave',
  summary: `Leave interaction convo`,
  path: '/interactions/{interactionId}/convo/leave',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'convo', 'leave'],
}

export const interactionConvoClose: OperationData<Types.InteractionConvoClose> = {
  method: 'post',
  name: 'interactionConvoClose',
  summary: `Close interaction convo`,
  path: '/interactions/{interactionId}/convo/close',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'convo', 'close'],
}

export const unboundInteractionDownloadRecording: OperationData<Types.UnboundInteractionDownloadRecording> = {
  method: 'get',
  name: 'unboundInteractionDownloadRecording',
  summary: `Download interaction recording`,
  path: '/interactions/{interactionId}/recording-content',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'recording-content'],
}

export const unboundInteractionDownloadVoicemail: OperationData<Types.UnboundInteractionDownloadVoicemail> = {
  method: 'get',
  name: 'unboundInteractionDownloadVoicemail',
  summary: `Download interaction voicemail`,
  path: '/interactions/{interactionId}/voicemail-content',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'voicemail-content'],
}

export const unboundInteractionCallTranscriptionResultsGetByInteraction: OperationData<Types.UnboundInteractionCallTranscriptionResultsGetByInteraction> =
  {
    method: 'get',
    name: 'unboundInteractionCallTranscriptionResultsGetByInteraction',
    summary: `Get call transcription results for an interaction`,
    path: '/interactions/{interactionId}/call-transcripts',
    queryKey: ({ interactionId }) => ['interactions', interactionId, 'call-transcripts'],
  }

export const unboundInteractionSubmitCallTranscriptionRequest: OperationData<Types.UnboundInteractionSubmitCallTranscriptionRequest> =
  {
    method: 'post',
    name: 'unboundInteractionSubmitCallTranscriptionRequest',
    summary: `Submit a request for call transcription`,
    path: '/interactions/{interactionId}/call-transcripts',
    queryKey: ({ interactionId }) => ['interactions', interactionId, 'call-transcripts'],
  }

export const unboundInteractionCallTranscriptionResultsGet: OperationData<Types.UnboundInteractionCallTranscriptionResultsGet> =
  {
    method: 'get',
    name: 'unboundInteractionCallTranscriptionResultsGet',
    summary: `Get call transcription results`,
    path: '/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
    queryKey: ({ interactionId, aICallTranscriptionResultId }) => [
      'interactions',
      interactionId,
      'call-transcripts',
      aICallTranscriptionResultId,
    ],
  }

export const unboundInteractionCallTranscriptionResultsUpdate: OperationData<Types.UnboundInteractionCallTranscriptionResultsUpdate> =
  {
    method: 'put',
    name: 'unboundInteractionCallTranscriptionResultsUpdate',
    summary: `Update call transcription results`,
    path: '/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
    queryKey: ({ interactionId, aICallTranscriptionResultId }) => [
      'interactions',
      interactionId,
      'call-transcripts',
      aICallTranscriptionResultId,
    ],
  }

export const unboundInteractionSubmitCallSummaryRequest: OperationData<Types.UnboundInteractionSubmitCallSummaryRequest> =
  {
    method: 'post',
    name: 'unboundInteractionSubmitCallSummaryRequest',
    summary: `Submit a request for call summary`,
    path: '/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries',
    queryKey: ({ interactionId, aICallTranscriptionResultId }) => [
      'interactions',
      interactionId,
      'call-transcripts',
      aICallTranscriptionResultId,
      'summaries',
    ],
  }

export const unboundInteractionCallSummaryResultsGet: OperationData<Types.UnboundInteractionCallSummaryResultsGet> = {
  method: 'get',
  name: 'unboundInteractionCallSummaryResultsGet',
  summary: `Get call summary results`,
  path: '/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
  queryKey: ({ interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    aICallSummaryResultId,
  ],
}

export const unboundInteractionCallSummaryResultsUpdate: OperationData<Types.UnboundInteractionCallSummaryResultsUpdate> =
  {
    method: 'put',
    name: 'unboundInteractionCallSummaryResultsUpdate',
    summary: `Update call summary results`,
    path: '/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
    queryKey: ({ interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
      'interactions',
      interactionId,
      'call-transcripts',
      aICallTranscriptionResultId,
      'summaries',
      aICallSummaryResultId,
    ],
  }

export const unboundInteractionCallSummaryResultsGetByInteraction: OperationData<Types.UnboundInteractionCallSummaryResultsGetByInteraction> =
  {
    method: 'get',
    name: 'unboundInteractionCallSummaryResultsGetByInteraction',
    summary: `Get interaction call summaries`,
    path: '/interactions/{interactionId}/call-transcripts/summaries',
    queryKey: ({ interactionId }) => ['interactions', interactionId, 'call-transcripts', 'summaries'],
  }

export const interactionGetAll: OperationData<Types.InteractionGetAll> = {
  method: 'get',
  name: 'interactionGetAll',
  summary: `Get borrower interactions`,
  path: '/people/{personId}/interactions',
  queryKey: ({ personId }) => ['people', personId, 'interactions'],
}

export const interactionAdd: OperationData<Types.InteractionAdd> = {
  method: 'post',
  name: 'interactionAdd',
  summary: `Create interaction`,
  path: '/people/{personId}/interactions',
  queryKey: ({ personId }) => ['people', personId, 'interactions'],
}

export const interactionGetById: OperationData<Types.InteractionGetById> = {
  method: 'get',
  name: 'interactionGetById',
  summary: `Get interaction by ID`,
  path: '/people/{personId}/interactions/{interactionId}',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId],
}

export const interactionUpdate: OperationData<Types.InteractionUpdate> = {
  method: 'put',
  name: 'interactionUpdate',
  summary: `Update interaction`,
  path: '/people/{personId}/interactions/{interactionId}',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId],
}

export const interactionDelete: OperationData<Types.InteractionDelete> = {
  method: 'delete',
  name: 'interactionDelete',
  summary: `Delete interaction`,
  path: '/people/{personId}/interactions/{interactionId}',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId],
}

export const getManyPreviousInteractions: OperationData<Types.GetManyPreviousInteractions> = {
  method: 'get',
  name: 'getManyPreviousInteractions',
  summary: `Get previous interactions`,
  path: '/people/{personId}/interactions/{interactionId}/previous',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'previous'],
}

export const interactionDownloadRecording: OperationData<Types.InteractionDownloadRecording> = {
  method: 'get',
  name: 'interactionDownloadRecording',
  summary: `Download interaction recording`,
  path: '/people/{personId}/interactions/{interactionId}/recording-content',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'recording-content'],
}

export const interactionDownloadVoicemail: OperationData<Types.InteractionDownloadVoicemail> = {
  method: 'get',
  name: 'interactionDownloadVoicemail',
  summary: `Download interaction voicemail`,
  path: '/people/{personId}/interactions/{interactionId}/voicemail-content',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'voicemail-content'],
}

export const callTranscriptionResultsGetByInteraction: OperationData<Types.CallTranscriptionResultsGetByInteraction> = {
  method: 'get',
  name: 'callTranscriptionResultsGetByInteraction',
  summary: `Get call transcription results for an interaction`,
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'call-transcripts'],
}

export const submitCallTranscriptionRequest: OperationData<Types.SubmitCallTranscriptionRequest> = {
  method: 'post',
  name: 'submitCallTranscriptionRequest',
  summary: `Submit a request for call transcription`,
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'call-transcripts'],
}

export const callTranscriptionResultsGet: OperationData<Types.CallTranscriptionResultsGet> = {
  method: 'get',
  name: 'callTranscriptionResultsGet',
  summary: `Get call transcription results`,
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
  queryKey: ({ personId, interactionId, aICallTranscriptionResultId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
  ],
}

export const callTranscriptionResultsUpdate: OperationData<Types.CallTranscriptionResultsUpdate> = {
  method: 'put',
  name: 'callTranscriptionResultsUpdate',
  summary: `Update call transcription results`,
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
  queryKey: ({ personId, interactionId, aICallTranscriptionResultId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
  ],
}

export const submitCallSummaryRequest: OperationData<Types.SubmitCallSummaryRequest> = {
  method: 'post',
  name: 'submitCallSummaryRequest',
  summary: `Submit a request for call summary`,
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries',
  queryKey: ({ personId, interactionId, aICallTranscriptionResultId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
  ],
}

export const callSummaryResultsGet: OperationData<Types.CallSummaryResultsGet> = {
  method: 'get',
  name: 'callSummaryResultsGet',
  summary: `Get call summary results`,
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
  queryKey: ({ personId, interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    aICallSummaryResultId,
  ],
}

export const callSummaryResultsUpdate: OperationData<Types.CallSummaryResultsUpdate> = {
  method: 'put',
  name: 'callSummaryResultsUpdate',
  summary: `Update call summary results`,
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
  queryKey: ({ personId, interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    aICallSummaryResultId,
  ],
}

export const callSummaryResultsGetByInteraction: OperationData<Types.CallSummaryResultsGetByInteraction> = {
  method: 'get',
  name: 'callSummaryResultsGetByInteraction',
  summary: `Get interaction call summaries`,
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/summaries',
  queryKey: ({ personId, interactionId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    'summaries',
  ],
}

export const getInteractionCases: OperationData<Types.GetInteractionCases> = {
  method: 'get',
  name: 'getInteractionCases',
  summary: `Get cases associated with an interaction`,
  path: '/people/{personId}/interactions/{interactionId}/cases',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'cases'],
}

export const interactionSearch: OperationData<Types.InteractionSearch> = {
  method: 'get',
  name: 'interactionSearch',
  summary: `Search a borrower's interactions`,
  path: '/people/{personId}/interactions/search',
  queryKey: ({ personId }) => ['people', personId, 'interactions', 'search'],
}

export const internalUnboundInteractionGetAll: OperationData<Types.InternalUnboundInteractionGetAll> = {
  method: 'get',
  name: 'internalUnboundInteractionGetAll',
  summary: `Get interactions`,
  path: '/companies/{companyId}/interactions',
  queryKey: ({ companyId }) => ['companies', companyId, 'interactions'],
}

export const unboundInteractionAdd: OperationData<Types.UnboundInteractionAdd> = {
  method: 'post',
  name: 'unboundInteractionAdd',
  summary: `Create unbound interaction`,
  path: '/companies/{companyId}/interactions',
  queryKey: ({ companyId }) => ['companies', companyId, 'interactions'],
}

export const internalUnboundInteractionGetById: OperationData<Types.InternalUnboundInteractionGetById> = {
  method: 'get',
  name: 'internalUnboundInteractionGetById',
  summary: `Get unbound interaction by ID`,
  path: '/companies/{companyId}/interactions/{interactionId}',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId],
}

export const unboundInteractionUpdate: OperationData<Types.UnboundInteractionUpdate> = {
  method: 'put',
  name: 'unboundInteractionUpdate',
  summary: `Update unbound interaction`,
  path: '/companies/{companyId}/interactions/{interactionId}',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId],
}

export const internalUnboundInteractionDelete: OperationData<Types.InternalUnboundInteractionDelete> = {
  method: 'delete',
  name: 'internalUnboundInteractionDelete',
  summary: `Delete unbound interaction`,
  path: '/companies/{companyId}/interactions/{interactionId}',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId],
}

export const internalUnboundGetManyPreviousInteractions: OperationData<Types.InternalUnboundGetManyPreviousInteractions> =
  {
    method: 'get',
    name: 'internalUnboundGetManyPreviousInteractions',
    summary: `Get previous interactions`,
    path: '/companies/{companyId}/interactions/{interactionId}/previous',
    queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, 'previous'],
  }

export const internalInteractionConvoGet: OperationData<Types.InternalInteractionConvoGet> = {
  method: 'get',
  name: 'internalInteractionConvoGet',
  summary: `Get interaction convo`,
  path: '/companies/{companyId}/interactions/{interactionId}/convo',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, 'convo'],
}

export const internalInteractionConvoJoin: OperationData<Types.InternalInteractionConvoJoin> = {
  method: 'post',
  name: 'internalInteractionConvoJoin',
  summary: `Join interaction convo`,
  path: '/companies/{companyId}/interactions/{interactionId}/convo/join',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, 'convo', 'join'],
}

export const internalInteractionConvoLeave: OperationData<Types.InternalInteractionConvoLeave> = {
  method: 'post',
  name: 'internalInteractionConvoLeave',
  summary: `Leave interaction convo`,
  path: '/companies/{companyId}/interactions/{interactionId}/convo/leave',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, 'convo', 'leave'],
}

export const internalInteractionConvoClose: OperationData<Types.InternalInteractionConvoClose> = {
  method: 'post',
  name: 'internalInteractionConvoClose',
  summary: `Close interaction convo`,
  path: '/companies/{companyId}/interactions/{interactionId}/convo/close',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, 'convo', 'close'],
}

export const internalUnboundInteractionDownloadRecording: OperationData<Types.InternalUnboundInteractionDownloadRecording> =
  {
    method: 'get',
    name: 'internalUnboundInteractionDownloadRecording',
    summary: `Download interaction recording`,
    path: '/companies/{companyId}/interactions/{interactionId}/recording-content',
    queryKey: ({ companyId, interactionId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'recording-content',
    ],
  }

export const internalUnboundInteractionDownloadVoicemail: OperationData<Types.InternalUnboundInteractionDownloadVoicemail> =
  {
    method: 'get',
    name: 'internalUnboundInteractionDownloadVoicemail',
    summary: `Download interaction voicemail`,
    path: '/companies/{companyId}/interactions/{interactionId}/voicemail-content',
    queryKey: ({ companyId, interactionId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'voicemail-content',
    ],
  }

export const internalUnboundInteractionCallTranscriptionResultsGetByInteraction: OperationData<Types.InternalUnboundInteractionCallTranscriptionResultsGetByInteraction> =
  {
    method: 'get',
    name: 'internalUnboundInteractionCallTranscriptionResultsGetByInteraction',
    summary: `Get call transcription results for an interaction`,
    path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts',
    queryKey: ({ companyId, interactionId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'call-transcripts',
    ],
  }

export const internalUnboundInteractionSubmitCallTranscriptionRequest: OperationData<Types.InternalUnboundInteractionSubmitCallTranscriptionRequest> =
  {
    method: 'post',
    name: 'internalUnboundInteractionSubmitCallTranscriptionRequest',
    summary: `Submit a request for call transcription`,
    path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts',
    queryKey: ({ companyId, interactionId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'call-transcripts',
    ],
  }

export const internalUnboundInteractionCallTranscriptionResultsGet: OperationData<Types.InternalUnboundInteractionCallTranscriptionResultsGet> =
  {
    method: 'get',
    name: 'internalUnboundInteractionCallTranscriptionResultsGet',
    summary: `Get call transcription results`,
    path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
    queryKey: ({ companyId, interactionId, aICallTranscriptionResultId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'call-transcripts',
      aICallTranscriptionResultId,
    ],
  }

export const internalUnboundInteractionCallTranscriptionResultsUpdate: OperationData<Types.InternalUnboundInteractionCallTranscriptionResultsUpdate> =
  {
    method: 'put',
    name: 'internalUnboundInteractionCallTranscriptionResultsUpdate',
    summary: `Update call transcription results`,
    path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
    queryKey: ({ companyId, interactionId, aICallTranscriptionResultId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'call-transcripts',
      aICallTranscriptionResultId,
    ],
  }

export const internalUnboundInteractionSubmitCallSummaryRequest: OperationData<Types.InternalUnboundInteractionSubmitCallSummaryRequest> =
  {
    method: 'post',
    name: 'internalUnboundInteractionSubmitCallSummaryRequest',
    summary: `Submit a request for call summary`,
    path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries',
    queryKey: ({ companyId, interactionId, aICallTranscriptionResultId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'call-transcripts',
      aICallTranscriptionResultId,
      'summaries',
    ],
  }

export const internalUnboundInteractionCallSummaryResultsGet: OperationData<Types.InternalUnboundInteractionCallSummaryResultsGet> =
  {
    method: 'get',
    name: 'internalUnboundInteractionCallSummaryResultsGet',
    summary: `Get call summary results`,
    path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
    queryKey: ({ companyId, interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'call-transcripts',
      aICallTranscriptionResultId,
      'summaries',
      aICallSummaryResultId,
    ],
  }

export const internalUnboundInteractionCallSummaryResultsUpdate: OperationData<Types.InternalUnboundInteractionCallSummaryResultsUpdate> =
  {
    method: 'put',
    name: 'internalUnboundInteractionCallSummaryResultsUpdate',
    summary: `Update call summary results`,
    path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
    queryKey: ({ companyId, interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'call-transcripts',
      aICallTranscriptionResultId,
      'summaries',
      aICallSummaryResultId,
    ],
  }

export const internalUnboundInteractionCallSummaryResultsGetByInteraction: OperationData<Types.InternalUnboundInteractionCallSummaryResultsGetByInteraction> =
  {
    method: 'get',
    name: 'internalUnboundInteractionCallSummaryResultsGetByInteraction',
    summary: `Get interaction call summaries`,
    path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/summaries',
    queryKey: ({ companyId, interactionId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'call-transcripts',
      'summaries',
    ],
  }
