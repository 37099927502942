import { AnchorHTMLAttributes, FC, HTMLAttributes } from 'react'

import { Link, LinkProps } from 'react-router-dom'
import { styled, css } from 'styled-components'

import { DropdownMenuDivider, DropdownMenuItem } from 'core/components/lib/DropdownMenu'
import { variables } from 'core/styles'

// The styling of this matches a <Select /> element, but using a form element
// to render this menu is semantically incorrect. Designs should be updated to
// differentiate between dropdown menus and form selects.

export const UserButton = styled.button<{ ref: any }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${variables.colorBlack30};
  border-radius: 4px;
  background-color: ${variables.colorWhite};
  cursor: pointer;
  padding: 8px;
  min-width: 200px;
  max-width: 214px;
  color: ${variables.colorBlack70};
  font-size: 16px;

  &[data-state='open'],
  &:hover {
    max-width: initial;
  }

  &[data-state='open'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: ${variables.colorBluePrimary};
  }

  &[data-state='closed'] {
    &:hover {
      border-color: ${variables.colorBlack40};
    }
  }
`

export const UserNameAndIcon = styled.span`
  display: flex;
  flex-shrink: 1;
  align-items: center;
  min-width: 0;

  > span {
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const UserDropdownWrapper = styled.div<{ $width: number }>`
  box-sizing: border-box;
  z-index: 10;
  border-right: 1px solid ${variables.colorBluePrimary};
  border-bottom: 1px solid ${variables.colorBluePrimary};
  border-left: 1px solid ${variables.colorBluePrimary};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: ${variables.colorWhite};
  width: ${(p) => p.$width}px;
  overflow: hidden;
`

export const UserDropdownDivider = styled(DropdownMenuDivider)`
  background-color: ${variables.colorBlack20};
  height: 1px;
`

const dropdownItemStyles = css`
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px 12px;
  width: 100%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${variables.colorBlack70};
  font-size: 16px;

  &:hover {
    background-color: ${variables.colorBlack10};
  }
`

const StyledLink = styled(Link)`
  ${dropdownItemStyles}
  display: block;
`

const StyledLinkExternal = styled.a`
  ${dropdownItemStyles};
  display: block;
`

const StyledButton = styled.button`
  ${dropdownItemStyles}
`

export const UserDropdownLink: FC<LinkProps> = (props) => {
  return (
    <DropdownMenuItem>
      <StyledLink {...props} />
    </DropdownMenuItem>
  )
}

export const UserDropdownLinkExternal: FC<AnchorHTMLAttributes<HTMLAnchorElement>> = (props) => {
  return (
    <DropdownMenuItem>
      <StyledLinkExternal {...props} />
    </DropdownMenuItem>
  )
}

export const UserDropdownButton: FC<HTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <DropdownMenuItem>
      <StyledButton {...props} />
    </DropdownMenuItem>
  )
}
