import { R } from 'core/helpers'
import { useCompanyId } from 'core/SystemProvider/CompanyIdProvider'
import { Permission, useUserPermissions_get } from 'core/types'

const useHasPermission = (value?: Permission | Array<Permission>) => {
  const companyId = useCompanyId()

  const { data, isLoading } = useUserPermissions_get({})

  if (value === undefined || value.length === 0) return true

  if (R.isNil(data) || isLoading) return undefined

  const list = Array.isArray(value) ? value : [value]

  return list.every((key) => data[companyId]?.includes(key))
}

export default useHasPermission
